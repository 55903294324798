/**
 * Created by preference on 2020/07/15
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** 53.1.补尾款项目存量列表  */
  getArrearProjectAccount: params => {
    return API.POST('api/arrearAccount/projectAccount', params)
  },
  /** 53.2.补尾款产品存量列表  */
  getArrearProductAccount: params => {
    return API.POST('api/arrearAccount/productAccount', params)
  },
  /** 53.3.补尾款储值卡存量列表  */
  getArrearSavingCardAccount: params => {
    return API.POST('api/arrearAccount/savingCardAccount', params)
  },
  
  /** 53.4.补尾款通用次卡存量列表  */
  getArrearGeneralCardAccount: params => {
    return API.POST('api/arrearAccount/generalCardAccount', params)
  },
  /**  53.5.补尾款时效卡存量列表 */
  getArrearTimeCardAccount: params => {
    return API.POST('api/arrearAccount/timeCardAccount', params)
  },
  /**  53.6.补尾款套餐卡存量列表 */
  getArrearPackageCardAccount: params => {
    return API.POST('api/arrearAccount/packageCardAccount', params)
  },
  /**  53.7.补尾款套餐卡存量明细 */
  getArrearPackageCardAccountDetails: params => {
    return API.POST('api/arrearAccount/packageCardAccountDetails', params)
  },
  /**  53.8.补尾款结账 */
  getArrearCreate: params => {
    return API.POST('api/arrear/create', params)
  },
}