<template>
  <div style="height: calc(100% - 63px)" class="workOffArrears">
    <el-row class="project_content">
      <el-col :span="9" class="project_left">
        <div class="pad_15">
          <el-input
            placeholder="请输入商品名称、别名关键字，按回车搜索"
            prefix-icon="el-icon-search"
            v-model="goodsName"
            size="small"
            clearable
            @keyup.enter.native="searchGoodsClick"
            @clear="clearClick"
          ></el-input>
        </div>
        <el-tabs class="arrearGoods" v-model="tabPane" @tab-click="tabClick">
          <el-tab-pane
            label="项目"
            name="0"
            v-if="projectAccountList.length > 0"
          >
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card
                class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                :class="index == 0 ? 'martp_10' : ''"
                :body-style="{ padding: '0px' }"
                shadow="hover"
                v-for="(item, index) in projectAccountList"
                :key="index + 'ac_Project'"
                @click.native="ArrearProjectClick(item)"
              >
                <div slot="header">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag
                    v-if="item.IsLargess"
                    size="mini"
                    class="marlt_5"
                    type="danger"
                    >赠</el-tag
                  >
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效次数：</span>
                      <span>{{ item.ValidBalance }}</span>
                      <el-popover
                        class="marlt_5"
                        placement="top-start"
                        width="240"
                        trigger="hover"
                      >
                        <p>
                          有效次数=
                          剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                        </p>
                        <el-button
                          type="text"
                          style="color: #dcdfe6"
                          class="font_12 el-popover-botton-tip"
                          icon="el-icon-info"
                          slot="reference"
                        ></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余数量：</span>
                      <span>{{ item.Balance }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中数量：</span>
                      <span>{{ item.RefundBalance }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买价格：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买数量：</span>
                      <span>{{ item.Quantity }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane
            label="储值卡"
            name="1"
            v-if="savingCardAccountList.length > 0"
          >
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card
                class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                :class="index == 0 ? 'martp_10' : ''"
                :body-style="{ padding: '0px' }"
                shadow="hover"
                v-for="(item, index) in savingCardAccountList"
                :key="index + 'ac_savingCard'"
                @click.native="ArrearSavingCardClick(item)"
              >
                <div slot="header">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效余额：</span>
                      <span>¥ {{ item.ValidBalance | NumFormat }}</span>
                      <el-popover
                        class="marlt_5"
                        placement="top-start"
                        width="240"
                        trigger="hover"
                      >
                        <p>1.有效金额= 剩余数量-退款中金额-欠款金额；</p>
                        <p>
                          2.注意：如果有赠额的情况，赠额可用金额也跟退款金额和欠款金额有关；
                        </p>
                        <p>
                          3.比如：充值100送100，欠款50，则可用本金为50，可用赠额为50，可用余额为100。
                        </p>
                        <el-button
                          type="text"
                          style="color: #dcdfe6"
                          class="font_12 el-popover-botton-tip"
                          icon="el-icon-info"
                          slot="reference"
                        ></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余金额：</span>
                      <span>¥ {{ item.TotalBalance | NumFormat }}</span>
                      <el-popover
                        class="marlt_5"
                        placement="top-start"
                        width="240"
                        trigger="hover"
                      >
                        <p>剩余金额= 剩余本金+剩余赠额</p>
                        <el-button
                          type="text"
                          style="color: #dcdfe6"
                          class="font_12 el-popover-botton-tip"
                          icon="el-icon-info"
                          slot="reference"
                        ></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中金额：</span>
                      <span>¥ {{ item.RefundAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-col :span="8" class="border_right">
                  <div class="goods-item">
                    <span class="goods-lable">购买金额：</span>
                    <span>{{ item.TotalAmount | NumFormat }}</span>
                  </div>
                </el-col>
                <el-row class="border_bottom">
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余本金：</span>
                      <span>¥ {{ item.Balance | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">剩余赠额：</span>
                      <span>¥ {{ item.LargessBalance | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效期：</span>
                      <span>{{ item.ValidDayName }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane
            label="时效卡"
            name="2"
            v-if="timeCardAccountList.length > 0"
          >
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card
                class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                :class="index == 0 ? 'martp_10' : ''"
                :body-style="{ padding: '0px' }"
                shadow="hover"
                v-for="(item, index) in timeCardAccountList"
                :key="index + 'ac_timeCard'"
                @click.native="ArrearTimeCardClick(item)"
              >
                <div slot="header">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag
                    v-if="item.IsLargess"
                    size="mini"
                    class="marlt_5"
                    type="danger"
                    >赠</el-tag
                  >
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">周期内消耗次数：</span>
                      <span>{{ item.ConsumeCycleAmount }}</span>
                      <el-popover
                        class="marlt_5"
                        placement="top-start"
                        width="240"
                        trigger="hover"
                      >
                        <p>
                          当“周期内消耗次数”超过“消耗周期限制”，则不能使用。
                        </p>
                        <el-button
                          type="text"
                          style="color: #dcdfe6"
                          class="font_12 el-popover-botton-tip"
                          icon="el-icon-info"
                          slot="reference"
                        ></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">消耗周期限制：</span>
                      <span>{{
                        item.ConsumeCycle == 0 || item.CycleLimitAmount == 0
                          ? "不限制"
                          : item.CycleLimitAmount +
                            "(次)/" +
                            item.ConsumeCycle +
                            "(天)"
                      }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">累计耗用次数：</span>
                      <span>{{ item.ConsumeAmount }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买金额：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">业绩提成次数：</span>
                      <span>{{ item.PerformanceBalance }}</span>
                      <el-popover
                        class="marlt_5"
                        placement="top-start"
                        width="240"
                        trigger="hover"
                      >
                        <p>用于确认员工消耗业绩。</p>
                        <p>
                          比如：购买金额为1000，业绩提成次数为10，则每次使用时效卡耗做项目，则1~10次时，员工业绩为100，11次以后（包含第11次），员工业绩为0。
                        </p>
                        <el-button
                          type="text"
                          style="color: #dcdfe6"
                          class="font_12 el-popover-botton-tip"
                          icon="el-icon-info"
                          slot="reference"
                        ></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效期：</span>
                      <span>{{ item.ValidDayName }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane
            label="通用次卡"
            name="3"
            v-if="generalCardAccountList.length > 0"
          >
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card
                class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                :class="index == 0 ? 'martp_10' : ''"
                :body-style="{ padding: '0px' }"
                shadow="hover"
                v-for="(item, index) in generalCardAccountList"
                :key="index + 'ac_generalCard'"
                @click.native="ArrearGeneralCardClick(item)"
              >
                <div slot="header">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag
                    v-if="item.IsLargess"
                    size="mini"
                    class="marlt_5"
                    type="danger"
                    >赠</el-tag
                  >
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效次数：</span>
                      <span>{{ item.ValidBalance }}</span>
                      <el-popover
                        class="marlt_5"
                        placement="top-start"
                        width="240"
                        trigger="hover"
                      >
                        <p>
                          有效次数=
                          剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                        </p>
                        <el-button
                          type="text"
                          style="color: #dcdfe6"
                          class="font_12 el-popover-botton-tip"
                          icon="el-icon-info"
                          slot="reference"
                        ></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余次数：</span>
                      <span>{{ item.Balance }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中数量：</span>
                      <span>{{ item.RefundAmount }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买金额：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">卡总次数：</span>
                      <span>{{ item.CardTimes }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">有效期：</span>
                      <span>{{ item.ValidDayName }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane
            label="套餐卡"
            name="4"
            v-if="packageCardAccountList.length > 0"
          >
            <el-row class="category_project valueCard_project">
              <el-col :span="4" class="text_center category">
                <el-scrollbar class="el-scrollbar_height border_right">
                  <div
                    v-for="(item, index) in packageCardAccountList"
                    :key="index + 'ac_package'"
                    :class="[
                      currentSelectPackageCardIndex == index
                        ? 'category_select'
                        : '',
                      'pad_10_15',
                      'border_bottom',
                      'cursor_pointer',
                    ]"
                    @click="clickPackageCagegory(item, index)"
                  >
                    <span class="line_20">{{ item.Name }}</span>
                    <span class="line_20" v-if="item.Alias"
                      >({{ item.Alias }})</span
                    >
                    <el-tag
                      v-if="item.IsLargess"
                      size="mini"
                      class="marlt_5 line_20"
                      type="danger"
                      >赠</el-tag
                    >
                    <el-popover
                      class="marlt_5"
                      popper-class="popover-package"
                      placement="top-start"
                      width="400"
                      trigger="hover"
                    >
                      <el-card :body-style="{ padding: '0px' }">
                        <div slot="header">
                          <span>{{ item.Name }}</span>
                          <span v-if="item.Alias">({{ item.Alias }})</span>
                          <el-tag
                            v-if="item.IsLargess"
                            size="mini"
                            class="marlt_5"
                            type="danger"
                            >赠</el-tag
                          >
                        </div>
                        <el-row class="border_bottom">
                          <el-col :span="12" class="border_right">
                            <div class="goods-item">
                              <span class="goods-lable">购买价格：</span>
                              <span>¥ {{ item.TotalAmount | NumFormat }}</span>
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="goods-item">
                              <span class="goods-lable">欠款金额：</span>
                              <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                            </div>
                          </el-col>
                        </el-row>
                        <el-row class="border_bottom">
                          <el-col :span="12" class="border_right">
                            <div class="goods-item">
                              <span class="goods-lable">购买日期：</span>
                              <span>{{ item.BuyDate }}</span>
                            </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="goods-item">
                              <span class="goods-lable">有效期：</span>
                              <span>{{ item.ValidDayName }}</span>
                            </div>
                          </el-col>
                        </el-row>
                      </el-card>
                      <el-button
                        type="text"
                        style="color: #dcdfe6; padding: 0px"
                        class="font_12"
                        icon="el-icon-info"
                        slot="reference"
                      ></el-button>
                    </el-popover>
                  </div>
                </el-scrollbar>
              </el-col>
              <el-col
                :span="20"
                class="project"
                v-loading="packageDetailLoading"
              >
                <div class="producct_list">
                  <el-scrollbar class="el-scrollbar_height">
                    <!-- 套餐卡项目 -->
                    <el-card
                      class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                      :class="index == 0 ? 'martp_10' : ''"
                      :body-style="{ padding: '0px' }"
                      shadow="hover"
                      v-for="(item, index) in packageCardAccountDetail.Project"
                      :key="index + 'ac_pac_project'"
                      @click.native="ArrearPackageProjectClick(item)"
                    >
                      <div slot="header">
                        <el-tag size="mini">项目</el-tag>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag
                          v-if="item.IsLargess"
                          size="mini"
                          class="marlt_5"
                          type="danger"
                          >赠</el-tag
                        >
                      </div>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">有效次数：</span>
                            <span>{{ item.ValidBalance }}</span>
                            <el-popover
                              class="marlt_5"
                              placement="top-start"
                              width="240"
                              trigger="hover"
                            >
                              <p>
                                有效次数=
                                剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                              </p>
                              <el-button
                                type="text"
                                style="color: #dcdfe6"
                                class="font_12 el-popover-botton-tip"
                                icon="el-icon-info"
                                slot="reference"
                              ></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">剩余数量：</span>
                            <span>{{ item.Balance }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">退款中数量：</span>
                            <span>{{ item.RefundBalance }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">购买价格：</span>
                            <span v-if="item.IsLargess">¥ 0.00</span>
                            <span v-else
                              >¥ {{ item.TotalAmount | NumFormat }}</span
                            >
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买数量：</span>
                            <span>{{ item.Quantity }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">参考单价：</span>
                            <span>¥ {{ item.Amount | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">欠款金额：</span>
                            <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买日期：</span>
                            <span>{{ item.BuyDate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">有效期：</span>
                            <span>{{ item.ValidDayName }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-card>
                    <!-- 套餐卡储值卡 -->
                    <el-card
                      class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                      :class="index == 0 ? 'martp_10' : ''"
                      :body-style="{ padding: '0px' }"
                      shadow="hover"
                      v-for="(item,
                      index) in packageCardAccountDetail.SavingCard"
                      :key="index + 'ac_pac_savingCard'"
                      @click.native="ArrearPackageSavingCardClick(item)"
                    >
                      <div slot="header">
                        <el-tag size="mini">储值卡</el-tag>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                      </div>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">可用余额：</span>
                            <span>¥ {{ item.ValidBalance | NumFormat }}</span>
                            <el-popover
                              class="marlt_5"
                              placement="top-start"
                              width="240"
                              trigger="hover"
                            >
                              <p>1.有效金额= 剩余数量-退款中金额-欠款金额；</p>
                              <p>
                                2.注意：如果有赠额的情况，赠额可用金额也跟退款金额和欠款金额有关；
                              </p>
                              <p>
                                3.比如：充值100送100，欠款50，则可用本金为50，可用赠额为50，可用余额为100。
                              </p>
                              <el-button
                                type="text"
                                style="color: #dcdfe6"
                                class="font_12 el-popover-botton-tip"
                                icon="el-icon-info"
                                slot="reference"
                              ></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">剩余金额：</span>
                            <span>¥ {{ item.TotalBalance | NumFormat }}</span>
                            <el-popover
                              class="marlt_5"
                              placement="top-start"
                              width="240"
                              trigger="hover"
                            >
                              <p>剩余金额= 剩余本金+剩余赠额</p>
                              <el-button
                                type="text"
                                style="color: #dcdfe6"
                                class="font_12 el-popover-botton-tip"
                                icon="el-icon-info"
                                slot="reference"
                              ></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">退款中金额：</span>
                            <span>¥ {{ item.RefundAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">购买金额：</span>
                            <span>¥ {{ item.TotalAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">剩余本金：</span>
                            <span>¥ {{ item.Balance | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">剩余赠额：</span>
                            <span>¥ {{ item.LargessBalance | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">欠款金额：</span>
                            <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买日期：</span>
                            <span>{{ item.BuyDate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">有效期：</span>
                            <span>{{ item.ValidDayName }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-card>
                    <!-- 套餐卡时效卡 -->
                    <el-card
                      class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                      :class="index == 0 ? 'martp_10' : ''"
                      :body-style="{ padding: '0px' }"
                      shadow="hover"
                      v-for="(item, index) in packageCardAccountDetail.TimeCard"
                      :key="index + 'ac_pac_timeCard'"
                      @click.native="ArrearPackageTimeCardClick(item)"
                    >
                      <div slot="header">
                        <el-tag size="mini">时效卡</el-tag>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag
                          v-if="item.IsLargess"
                          size="mini"
                          class="marlt_5"
                          type="danger"
                          >赠</el-tag
                        >
                      </div>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">周期内消耗次数：</span>
                            <span>{{ item.ConsumeCycleAmount }}</span>
                            <el-popover
                              class="marlt_5"
                              placement="top-start"
                              width="240"
                              trigger="hover"
                            >
                              <p>
                                当“周期内消耗次数”超过“消耗周期限制”，则不能使用。
                              </p>
                              <el-button
                                type="text"
                                style="color: #dcdfe6"
                                class="font_12 el-popover-botton-tip"
                                icon="el-icon-info"
                                slot="reference"
                              ></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">消耗周期限制：</span>
                            <span>{{
                              item.ConsumeCycle == 0 ||
                              item.CycleLimitAmount == 0
                                ? "不限制"
                                : item.CycleLimitAmount +
                                  "(次)/" +
                                  item.ConsumeCycle +
                                  "(天)"
                            }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">累计耗用次数：</span>
                            <span>{{ item.ConsumeAmount }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">购买金额：</span>
                            <span v-if="item.IsLargess">¥ 0.00</span>
                            <span v-else
                              >¥ {{ item.TotalAmount | NumFormat }}</span
                            >
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">业绩提成次数：</span>
                            <span>{{ item.PerformanceBalance }}</span>
                            <el-popover
                              class="marlt_5"
                              placement="top-start"
                              width="240"
                              trigger="hover"
                            >
                              <p>用于确认员工消耗业绩。</p>
                              <p>
                                比如：购买金额为1000，业绩提成次数为10，则每次使用时效卡耗做项目，则1~10次时，员工业绩为100，11次以后（包含第11次），员工业绩为0。
                              </p>
                              <el-button
                                type="text"
                                style="color: #dcdfe6"
                                class="font_12 el-popover-botton-tip"
                                icon="el-icon-info"
                                slot="reference"
                              ></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">参考单价：</span>
                            <span>¥ {{ item.Amount | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">欠款金额：</span>
                            <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买日期：</span>
                            <span>{{ item.BuyDate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">有效期：</span>
                            <span>{{ item.ValidDayName }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-card>
                    <!-- 套餐卡通用次卡 -->
                    <el-card
                      class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                      :class="index == 0 ? 'martp_10' : ''"
                      :body-style="{ padding: '0px' }"
                      shadow="hover"
                      v-for="(item,
                      index) in packageCardAccountDetail.GeneralCard"
                      :key="index + 'ac_pac_generalCard'"
                      @click.native="ArrearPackageGeneralCardClick(item)"
                    >
                      <div slot="header">
                        <el-tag size="mini">通用次卡</el-tag>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag
                          v-if="item.IsLargess"
                          size="mini"
                          class="marlt_5"
                          type="danger"
                          >赠</el-tag
                        >
                      </div>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">有效次数：</span>
                            <span>{{ item.ValidBalance }}</span>
                            <el-popover
                              class="marlt_5"
                              placement="top-start"
                              width="240"
                              trigger="hover"
                            >
                              <p>
                                有效次数=
                                剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                              </p>
                              <el-button
                                type="text"
                                style="color: #dcdfe6"
                                class="font_12 el-popover-botton-tip"
                                icon="el-icon-info"
                                slot="reference"
                              ></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">剩余次数：</span>
                            <span>{{ item.Balance }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">退款中数量：</span>
                            <span>{{ item.RefundAmount }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">购买金额：</span>
                            <span v-if="item.IsLargess">¥ 0.00</span>
                            <span v-else
                              >¥ {{ item.TotalAmount | NumFormat }}</span
                            >
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">卡总次数：</span>
                            <span>{{ item.CardTimes }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">参考单价：</span>
                            <span>¥ {{ item.Amount | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">欠款金额：</span>
                            <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买日期：</span>
                            <span>{{ item.BuyDate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">有效期：</span>
                            <span>{{ item.ValidDayName }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-card>
                    <!-- 套餐卡产品 -->
                    <el-card
                      class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                      :class="index == 0 ? 'martp_10' : ''"
                      :body-style="{ padding: '0px' }"
                      shadow="hover"
                      v-for="(item, index) in packageCardAccountDetail.Product"
                      :key="index + 'ac_pac_product'"
                      @click.native="ArrearPackageProductClick(item)"
                    >
                      <div slot="header">
                        <el-tag size="mini">产品</el-tag>
                        <span>{{ item.Name }}</span>
                        <span v-if="item.Alias">({{ item.Alias }})</span>
                        <el-tag
                          v-if="item.IsLargess"
                          size="mini"
                          class="marlt_5"
                          type="danger"
                          >赠</el-tag
                        >
                      </div>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">有效数量：</span>
                            <span>{{ item.ValidBalance }}</span>
                            <el-popover
                              class="marlt_5"
                              placement="top-start"
                              width="240"
                              trigger="hover"
                            >
                              <p>
                                有效数量=
                                剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                              </p>
                              <el-button
                                type="text"
                                style="color: #dcdfe6"
                                class="font_12 el-popover-botton-tip"
                                icon="el-icon-info"
                                slot="reference"
                              ></el-button>
                            </el-popover>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">剩余数量：</span>
                            <span>{{ item.Balance }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">退款中数量：</span>
                            <span>{{ item.RefundBalance }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row class="border_bottom">
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">购买价格：</span>
                            <span v-if="item.IsLargess">¥ 0.00</span>
                            <span v-else
                              >¥ {{ item.TotalAmount | NumFormat }}</span
                            >
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买数量：</span>
                            <span>{{ item.Quantity }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">参考单价：</span>
                            <span>¥ {{ item.Amount | NumFormat }}</span>
                          </div>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="8" class="border_right">
                          <div class="goods-item">
                            <span class="goods-lable">欠款金额：</span>
                            <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8">
                          <div class="goods-item">
                            <span class="goods-lable">购买日期：</span>
                            <span>{{ item.BuyDate }}</span>
                          </div>
                        </el-col>
                        <el-col :span="8" class="border_left">
                          <div class="goods-item">
                            <span class="goods-lable">有效期：</span>
                            <span>{{ item.ValidDayName }}</span>
                          </div>
                        </el-col>
                      </el-row>
                    </el-card>
                  </el-scrollbar>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            label="产品"
            name="5"
            v-if="productAccountList.length > 0"
          >
            <el-scrollbar class="el-scrollbar_height el_scrollbar_project">
              <el-card
                class="marbm_10 marlt_10 marrt_10 cursor_pointer"
                :class="index == 0 ? 'martp_10' : ''"
                :body-style="{ padding: '0px' }"
                shadow="hover"
                v-for="(item, index) in productAccountList"
                :key="index + 'ac_product'"
                @click.native="ArrearProductClick(item)"
              >
                <div slot="header">
                  <span>{{ item.Name }}</span>
                  <span v-if="item.Alias">({{ item.Alias }})</span>
                  <el-tag
                    v-if="item.IsLargess"
                    size="mini"
                    class="marlt_5"
                    type="danger"
                    >赠</el-tag
                  >
                </div>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">有效数量：</span>
                      <span>{{ item.ValidBalance }}</span>
                      <el-popover
                        class="marlt_5"
                        placement="top-start"
                        width="240"
                        trigger="hover"
                      >
                        <p>
                          有效数量=
                          剩余数量-退款中数量-欠款占用的数量[欠款金额÷参考单价(向上取整)]
                        </p>
                        <el-button
                          type="text"
                          style="color: #dcdfe6"
                          class="font_12 el-popover-botton-tip"
                          icon="el-icon-info"
                          slot="reference"
                        ></el-button>
                      </el-popover>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">剩余数量：</span>
                      <span>{{ item.Balance }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">退款中数量：</span>
                      <span>{{ item.RefundBalance }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row class="border_bottom">
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">购买价格：</span>
                      <span v-if="item.IsLargess">¥ 0.00</span>
                      <span v-else>¥ {{ item.TotalAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8">
                    <div class="goods-item">
                      <span class="goods-lable">购买数量：</span>
                      <span>{{ item.Quantity }}</span>
                    </div>
                  </el-col>
                  <el-col :span="8" class="border_left">
                    <div class="goods-item">
                      <span class="goods-lable">参考单价：</span>
                      <span>¥ {{ item.Amount | NumFormat }}</span>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8" class="border_right">
                    <div class="goods-item">
                      <span class="goods-lable">欠款金额：</span>
                      <span>¥ {{ item.ArrearAmount | NumFormat }}</span>
                    </div>
                  </el-col>
                  <el-col :span="16">
                    <div class="goods-item">
                      <span class="goods-lable">购买日期：</span>
                      <span>{{ item.BuyDate }}</span>
                    </div>
                  </el-col>
                </el-row>
              </el-card>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </el-col>
      <el-col :span="15" class="project_right">
        <el-container style="height: 100%">
          <el-main>
            <el-scrollbar class="el-scrollbar_height">
              <!--项目-->
              <div v-if="selectProjectAccountList.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="10">项目</el-col>
                    <el-col :span="6">欠尾款</el-col>
                    <el-col :span="6">补欠款</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row
                  class="border_bottom"
                  v-for="(item, index) in selectProjectAccountList"
                  :key="index + 'sel_project'"
                >
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="10">{{ item.Name }}</el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          disabled
                          :value="item.ArrearAmount"
                          class="arrearSavingCardAmount"
                        ></el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          v-model="item.ReturnArrearAmount"
                          :min="0"
                          placeholder
                          class="arrearSavingCardAmount"
                          type="number"
                          v-enter-number
                          @input="(value) => changeArrearsAmount(value, item)"
                        ></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="mini"
                          @click="removeArrearsItemClick(item, index, 0)"
                        ></el-button>
                      </el-col>
                    </el-col>
                  </el-col>
                  <el-col :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row
                      class="cursor_pointer"
                      v-for="(handler, pIndex) in item.HandleTypeList"
                      :key="pIndex"
                      @click.native="employeeHandleClick(0, item)"
                      type="flex"
                      align="top"
                    >
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            :label="`${handler.Name}：`"
                          ></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            v-for="(employee, handleIndex) in handler.Employee"
                            :key="handleIndex"
                            :label="`${employee.EmployeeName} [${employee.EmployeeID}] `"
                          >
                            <el-input
                              class="employee_num"
                              v-model="employee.Discount"
                              size="mini"
                              :min="0"
                              :max="100"
                              type="number"
                              v-on:click.native.stop
                              v-enter-number
                              @input="
                                handlerPercentChange(handler.Employee, employee)
                              "
                            >
                              <template slot="append">%</template>
                            </el-input>
                            <i
                              class="el-icon-error marlt_5 font_16"
                              style="color: #909399; vertical-align: middle"
                              v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "
                            ></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--产品-->
              <div v-if="selectProductAccountList.length > 0">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="10">产品</el-col>
                    <el-col :span="6">欠尾款</el-col>
                    <el-col :span="6">补欠款</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row
                  class="border_bottom"
                  v-for="(item, index) in selectProductAccountList"
                  :key="index + 'sel_product'"
                >
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="10">{{ item.Name }}</el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          disabled
                          :value="item.ArrearAmount"
                          placeholder
                          class="arrearSavingCardAmount"
                          type="number"
                        ></el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          v-model="item.ReturnArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                          v-enter-number
                          @input="(value) => changeArrearsAmount(value, item)"
                        ></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="mini"
                          @click="removeArrearsItemClick(item, index, 1)"
                        ></el-button>
                      </el-col>
                    </el-col>
                  </el-col>
                  <el-col :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row
                      class="cursor_pointer"
                      v-for="(handler, pIndex) in item.HandleTypeList"
                      :key="pIndex"
                      @click.native="employeeHandleClick(5, item)"
                    >
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            :label="`${handler.Name}：`"
                          ></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            v-for="(employee, handleIndex) in handler.Employee"
                            :key="handleIndex"
                            :label="`${employee.EmployeeName} [${employee.EmployeeID}] `"
                          >
                            <el-input
                              class="employee_num"
                              v-model="employee.Discount"
                              size="mini"
                              :min="0"
                              :max="100"
                              type="number"
                              v-on:click.native.stop
                              v-enter-number
                              @input="
                                handlerPercentChange(handler.Employee, employee)
                              "
                            >
                              <template slot="append">%</template>
                            </el-input>
                            <!-- class="el-icon-error marlt_5 font_16 font_16"
                              style="color:#909399;vertical-align: middle" -->
                            <i
                              class="el-icon-error marlt_5 font_16"
                              style="color: #909399; vertical-align: middle"
                              v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "
                            ></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--储值卡-->
              <div v-if="selectSavingCardAccountList.length">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="10">储值卡</el-col>
                    <el-col :span="6">欠尾款</el-col>
                    <el-col :span="6">补欠款</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row
                  class="border_bottom"
                  v-for="(item, index) in selectSavingCardAccountList"
                  :key="index + 'sel_saingCard'"
                >
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="10">{{ item.Name }}</el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          disabled
                          :value="item.ArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                        ></el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          v-model="item.ReturnArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                          v-enter-number
                          @input="(value) => changeArrearsAmount(value, item)"
                        ></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="mini"
                          @click="removeArrearsItemClick(item, index, 2)"
                        ></el-button>
                      </el-col>
                    </el-col>
                  </el-col>
                  <el-col :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row
                      class="cursor_pointer"
                      v-for="(handler, pIndex) in item.HandleTypeList"
                      :key="pIndex"
                      @click.native="employeeHandleClick(1, item)"
                    >
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            :label="`${handler.Name}：`"
                          ></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            v-for="(employee, handleIndex) in handler.Employee"
                            :key="handleIndex"
                            :label="`${employee.EmployeeName} [${employee.EmployeeID}] `"
                          >
                            <el-input
                              class="employee_num"
                              v-model="employee.Discount"
                              size="mini"
                              :min="0"
                              :max="100"
                              type="number"
                              v-on:click.native.stop
                              v-enter-number
                              @input="
                                handlerPercentChange(handler.Employee, employee)
                              "
                            >
                              <template slot="append">%</template>
                            </el-input>
                            <i
                              class="el-icon-error marlt_5 font_16"
                              style="color: #909399; vertical-align: middle"
                              v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "
                            ></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--时效卡-->
              <div v-if="selectTimeCardAccountList.length">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="10">时效卡</el-col>
                    <el-col :span="6">欠尾款</el-col>
                    <el-col :span="6">补欠款</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row
                  class="border_bottom"
                  v-for="(item, index) in selectTimeCardAccountList"
                  :key="index + 'sel_timeCard'"
                >
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="10">{{ item.Name }}</el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          disabled
                          :value="item.ArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                        ></el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          v-model="item.ReturnArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                          v-enter-number
                          @input="(value) => changeArrearsAmount(value, item)"
                        ></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="mini"
                          @click="removeArrearsItemClick(item, index, 4)"
                        ></el-button>
                      </el-col>
                    </el-col>
                  </el-col>

                  <el-col :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row
                      class="cursor_pointer"
                      v-for="(handler, pIndex) in item.HandleTypeList"
                      :key="pIndex"
                      @click.native="employeeHandleClick(2, item)"
                    >
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            :label="`${handler.Name}：`"
                          ></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            v-for="(employee, handleIndex) in handler.Employee"
                            :key="handleIndex"
                            :label="`${employee.EmployeeName} [${employee.EmployeeID}] `"
                          >
                            <el-input
                              class="employee_num"
                              v-model="employee.Discount"
                              size="mini"
                              :min="0"
                              :max="100"
                              type="number"
                              v-on:click.native.stop
                              v-enter-number
                              @input="
                                handlerPercentChange(handler.Employee, employee)
                              "
                            >
                              <template slot="append">%</template>
                            </el-input>
                            <i
                              class="el-icon-error marlt_5 font_16"
                              style="color: #909399; vertical-align: middle"
                              v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "
                            ></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--通用次卡-->
              <div v-if="selectGeneralCardAccountList.length">
                <el-row class="row_header border_bottom">
                  <el-col :span="24">
                    <el-col :span="10">通用次卡</el-col>
                    <el-col :span="6">欠尾款</el-col>
                    <el-col :span="6">补欠款</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>
                </el-row>
                <el-row
                  class="border_bottom"
                  v-for="(item, index) in selectGeneralCardAccountList"
                  :key="index + 'sel_generalCard'"
                >
                  <el-col :span="24" class="pad_10 border_bottom">
                    <el-col :span="24">
                      <el-col :span="10">{{ item.Name }}</el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          disabled
                          :value="item.ArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                        ></el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          v-model="item.ReturnArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                          v-enter-number
                          @input="(value) => changeArrearsAmount(value, item)"
                        ></el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="mini"
                          @click="removeArrearsItemClick(item, index, 5)"
                        ></el-button>
                      </el-col>
                    </el-col>
                  </el-col>
                  <el-col :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row
                      class="cursor_pointer"
                      v-for="(handler, pIndex) in item.HandleTypeList"
                      :key="pIndex"
                      @click.native="employeeHandleClick(3, item)"
                    >
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            :label="`${handler.Name}：`"
                          ></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            v-for="(employee, handleIndex) in handler.Employee"
                            :key="handleIndex"
                            :label="`${employee.EmployeeName} [${employee.EmployeeID}] `"
                          >
                            <el-input
                              class="employee_num"
                              v-model="employee.Discount"
                              size="mini"
                              :min="0"
                              :max="100"
                              type="number"
                              v-on:click.native.stop
                              v-enter-number
                              @input="
                                handlerPercentChange(handler.Employee, employee)
                              "
                            >
                              <template slot="append">%</template>
                            </el-input>
                            <i
                              class="el-icon-error marlt_5 font_16"
                              style="color: #909399; vertical-align: middle"
                              v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "
                            ></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <!--套餐卡-->
              <div v-if="selectPackageCardAccountList.length">
                <el-row
                  v-for="(item, index) in selectPackageCardAccountList"
                  :key="index + 'sel_package'"
                >
                  <el-col :span="24" class="row_header">
                    <el-col :span="10">套餐卡--{{ item.Name }}</el-col>
                    <el-col :span="6">欠尾款</el-col>
                    <el-col :span="6">补欠款</el-col>
                    <el-col :span="1"></el-col>
                  </el-col>

                  <div v-if="item.Project.length > 0">
                    <el-col
                      v-for="(project, projectIndex) in item.Project"
                      :key="projectIndex + 'sel_psel_project'"
                      class="pad_10 border_bottom"
                    >
                      <el-col :span="10">
                        <el-tag size="mini marrt_10">项目</el-tag>
                        {{ project.Name }}({{ project.Alias }})
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          disabled
                          :value="project.ArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          v-model="project.ReturnArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                          v-enter-number
                          @input="
                            (value) => changeArrearsAmount(value, project)
                          "
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="mini"
                          @click="
                            removeArrearPackageProjectItemClick(
                              item,
                              index,
                              projectIndex
                            )
                          "
                        >
                        </el-button>
                      </el-col>
                    </el-col>
                  </div>
                  <div v-if="item.SavingCard.length > 0">
                    <el-col
                      v-for="(savingCardItem,
                      savingCardIndex) in item.SavingCard"
                      :key="savingCardIndex + 'sel_psel_savingCard'"
                      class="pad_10 border_bottom"
                    >
                      <el-col :span="10">
                        <el-tag size="mini marrt_10">储值卡</el-tag>
                        {{ savingCardItem.Name }}({{ savingCardItem.Alias }})
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          disabled
                          :value="savingCardItem.ArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          v-model="savingCardItem.ReturnArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                          v-enter-number
                          @input="
                            (value) =>
                              changeArrearsAmount(value, savingCardItem)
                          "
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="mini"
                          @click="
                            removeArrearPackageSavingCardItemClick(
                              item,
                              index,
                              savingCardIndex
                            )
                          "
                        >
                        </el-button>
                      </el-col>
                    </el-col>
                  </div>
                  <div v-if="item.TimeCard.length > 0">
                    <el-col
                      v-for="(TimeCardItem, TimeCardIndex) in item.TimeCard"
                      :key="TimeCardIndex + 'sel_psel_timeCard'"
                      class="pad_10 border_bottom"
                    >
                      <el-col :span="10">
                        <el-tag size="mini marrt_10">时效卡</el-tag>
                        {{ TimeCardItem.Name }}({{ TimeCardItem.Alias }})
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          disabled
                          :value="TimeCardItem.ArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          v-model="TimeCardItem.ReturnArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                          v-enter-number
                          @input="
                            (value) => changeArrearsAmount(value, TimeCardItem)
                          "
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="mini"
                          @click="
                            removeArrearPackageTimeCardItemClick(
                              item,
                              index,
                              TimeCardIndex
                            )
                          "
                        >
                        </el-button>
                      </el-col>
                    </el-col>
                  </div>

                  <div v-if="item.GeneralCard.length > 0">
                    <el-col
                      v-for="(GeneralCardCardItem,
                      GeneralCardIndex) in item.GeneralCard"
                      :key="GeneralCardIndex + 'sel_psel_generalCard'"
                      class="pad_10 border_bottom"
                    >
                      <el-col :span="10">
                        <el-tag size="mini marrt_10">通用次卡</el-tag>
                        {{ GeneralCardCardItem.Name }}({{
                          GeneralCardCardItem.Alias
                        }})
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          disabled
                          :value="GeneralCardCardItem.ArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          v-model="GeneralCardCardItem.ReturnArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                          v-enter-number
                          @input="
                            (value) =>
                              changeArrearsAmount(value, GeneralCardCardItem)
                          "
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="mini"
                          @click="
                            removeArrearPackageGeneralCardItemClick(
                              item,
                              index,
                              GeneralCardIndex
                            )
                          "
                        >
                        </el-button>
                      </el-col>
                    </el-col>
                  </div>
                  <div v-if="item.Product.length > 0">
                    <el-col
                      v-for="(ProductItem, ProductIndex) in item.Product"
                      :key="ProductIndex + 'sel_psel_Product'"
                      class="pad_10 border_bottom"
                    >
                      <el-col :span="10">
                        <el-tag size="mini marrt_10">产品</el-tag>
                        {{ ProductItem.Name }}({{ ProductItem.Alias }})
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          disabled
                          :value="ProductItem.ArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="6">
                        <el-input
                          size="mini"
                          v-model="ProductItem.ReturnArrearAmount"
                          placeholder
                          :min="0"
                          class="arrearSavingCardAmount"
                          type="number"
                          v-enter-number
                          @input="
                            (value) => changeArrearsAmount(value, ProductItem)
                          "
                        >
                        </el-input>
                      </el-col>
                      <el-col :span="1">
                        <el-button
                          type="danger"
                          icon="el-icon-delete"
                          circle
                          size="mini"
                          @click="
                            removeArrearPackageProductItemClick(
                              item,
                              index,
                              ProductIndex
                            )
                          "
                        >
                        </el-button>
                      </el-col>
                    </el-col>
                  </div>

                  <el-col :span="24" class="pad_10 padbm_0 border_bottom">
                    <el-row
                      class="cursor_pointer"
                      v-for="(handler, handIndex) in item.HandleTypeList"
                      :key="handIndex"
                      @click.native="employeeHandleClick(4, item)"
                    >
                      <el-col :span="4">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            :label="`${handler.Name}：`"
                          ></el-form-item>
                        </el-form>
                      </el-col>
                      <el-col :span="20">
                        <el-form :inline="true" size="mini">
                          <el-form-item
                            style="margin-bottom: 10px"
                            v-for="(employee, handleIndex) in handler.Employee"
                            :key="handleIndex"
                            :label="`${employee.EmployeeName} [${employee.EmployeeID}] `"
                          >
                            <el-input
                              class="employee_num"
                              v-model="employee.Discount"
                              size="mini"
                              :min="0"
                              :max="100"
                              type="number"
                              v-on:click.native.stop
                              v-enter-number
                              @input="
                                handlerPercentChange(handler.Employee, employee)
                              "
                            >
                              <template slot="append">%</template>
                            </el-input>
                            <i
                              class="el-icon-error marlt_5 font_16"
                              style="color: #909399; vertical-align: middle"
                              v-on:click.stop="
                                removeHandleClick(handler, handleIndex)
                              "
                            ></i>
                          </el-form-item>
                        </el-form>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
            </el-scrollbar>
          </el-main>
          <el-footer class="border_top">
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <span class="font_14 color_maroon"
                  >待收款金额：¥
                  {{ arrearTotalAmount | Fixed_2 | NumFormat }}</span
                >
              </el-col>
              <el-col :span="15">
                <el-input
                  type="textarea"
                  :rows="1"
                  placeholder="请输入备注信息"
                  v-model="remark"
                ></el-input>
              </el-col>
              <el-col :span="3" class="text_right">
                <el-button type="primary" size="small" @click="billClick"
                  >收款</el-button
                >
              </el-col>
            </el-row>
          </el-footer>
        </el-container>
      </el-col>
    </el-row>

    <!--经手人-->
    <el-dialog title="经手人" :visible.sync="dialogVisible" width="800px">
      <div>
        <el-row class="padbm_10">
          <el-col :span="8">
            <el-input
              placeholder="请输入员工编号、姓名"
              prefix-icon="el-icon-search"
              v-model="handlerName"
              size="small"
              clearable
            ></el-input>
          </el-col>
        </el-row>
        <el-tabs v-model="tabHandle">
          <el-tab-pane
            :label="handler.Name"
            :name="`${index}`"
            v-for="(handler, index) in handlerList"
            :key="index"
          >
            <el-row style="max-height: 300px; overflow-y: auto">
              <el-col
                :span="12"
                v-for="item in handler.Employee.filter(
                  (item) =>
                    !handlerName ||
                    item.EmployeeName.toLowerCase().includes(
                      handlerName.toLowerCase()
                    ) ||
                    item.EmployeeID.toLowerCase().includes(
                      handlerName.toLowerCase()
                    )
                )"
                :key="item.EmployeeID"
                class="marbm_10 dis_flex flex_y_center"
              >
                <el-checkbox
                  v-model="item.Checked"
                  @change="handlerCheckedChange(handler.Employee, item)"
                >
                  <span class="marrt_10"
                    >{{ item.EmployeeName }} [{{ item.EmployeeID }}]</span
                  >
                </el-checkbox>
                <el-input
                  placeholder
                  v-model="item.Discount"
                  style="width: 120px"
                  type="number"
                  size="mini"
                  min="0"
                  max="100"
                  v-enter-number
                  @input="handlerPercentChange(handler.Employee, item)"
                >
                  <template slot="append">%</template>
                </el-input>
              </el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" size="small" @click="submitHandleClick"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!--结账-->
    <el-dialog
      title="收银台"
      :visible.sync="dialogBill"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="900px"
    >
      <div>
        <el-row>
          <el-col :span="8">
            <el-scrollbar class="el-scrollbar_height" style="height: 500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span
                    class="flex_box text_center font_16"
                    style="line-height: 32px"
                    >{{ entityName }}</span
                  >
                </div>
                <el-divider>
                  <span class="font_12 color_gray">订单详情</span>
                </el-divider>
                <div class="dis_flex">
                  <span
                    class="flex_box font_12 color_gray text-left line_height_24"
                    >下单时间</span
                  >
                  <span
                    class="font_12 text_right line_height_24"
                    style="flex: 3"
                    >{{ getBillDate() }}</span
                  >
                </div>
                <div class="dis_flex">
                  <span
                    class="flex_box font_12 color_gray text-left line_height_24"
                    >会员姓名</span
                  >
                  <span class="flex_box font_12 text_right line_height_24">{{
                    customerFullName
                  }}</span>
                </div>
                <div class="dis_flex">
                  <span
                    class="flex_box font_12 color_gray text-left line_height_24"
                    >会员手机号</span
                  >
                  <span class="flex_box font_12 text_right line_height_24">{{
                    customerPhoneNumber
                  }}</span>
                </div>
                <div class="dis_flex">
                  <span
                    class="flex_box font_12 color_gray text-left line_height_24"
                    >收银员</span
                  >
                  <span class="flex_box font_12 text_right line_height_24">{{
                    userName
                  }}</span>
                </div>
                <el-divider>
                  <span class="font_12 color_gray">消费明细</span>
                </el-divider>
                <template v-for="(item, index) in selectProjectAccountList">
                  <div :key="index + item.ID">
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24"
                        style="flex: 2"
                      >
                        {{ index + 1 }} {{ item.Name }}
                      </span>
                    </div>
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24 marlt_10"
                        style="flex: 2"
                        >补尾款</span
                      >
                      <span
                        class="font_12 text_right line_height_24"
                        style="flex: 1"
                        >¥{{ item.ReturnArrearAmount | NumFormat }}</span
                      >
                    </div>
                  </div>
                </template>

                <template v-for="(item, index) in selectProductAccountList">
                  <div :key="index + item.ID">
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24"
                        style="flex: 2"
                      >
                        {{ index + 1 + selectProjectAccountList.length }}:
                        {{ item.Name }}
                      </span>
                    </div>
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24 marlt_10"
                        style="flex: 2"
                        >补尾款</span
                      >
                      <span
                        class="font_12 text_right line_height_24"
                        style="flex: 1"
                        >¥{{ item.ReturnArrearAmount | NumFormat }}</span
                      >
                    </div>
                  </div>
                </template>

                <template v-for="(item, index) in selectSavingCardAccountList">
                  <div :key="index + item.ID">
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24"
                        style="flex: 2"
                      >
                        {{
                          index +
                          1 +
                          selectProjectAccountList.length +
                          selectProductAccountList.length
                        }}: {{ item.Name }}
                      </span>
                    </div>
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24 marlt_10"
                        style="flex: 2"
                        >补尾款</span
                      >
                      <span
                        class="font_12 text_right line_height_24"
                        style="flex: 1"
                        >¥{{ item.ReturnArrearAmount | NumFormat }}</span
                      >
                    </div>
                  </div>
                </template>

                <template v-for="(item, index) in selectTimeCardAccountList">
                  <div :key="index + item.ID">
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24"
                        style="flex: 2"
                      >
                        {{
                          index +
                          1 +
                          selectProjectAccountList.length +
                          selectProductAccountList.length +
                          selectSavingCardAccountList.length
                        }}:{{ item.Name }}
                      </span>
                    </div>
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24 marlt_10"
                        style="flex: 2"
                        >补尾款</span
                      >
                      <span
                        class="font_12 text_right line_height_24"
                        style="flex: 1"
                        >¥{{ item.ReturnArrearAmount | NumFormat }}</span
                      >
                    </div>
                  </div>
                </template>

                <template v-for="(item, index) in selectGeneralCardAccountList">
                  <div :key="index + item.ID">
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24"
                        style="flex: 2"
                      >
                        {{
                          index +
                          1 +
                          selectProjectAccountList.length +
                          selectProductAccountList.length +
                          selectSavingCardAccountList.length +
                          selectTimeCardAccountList.length
                        }}:{{ item.Name }}
                      </span>
                    </div>
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24 marlt_10"
                        style="flex: 2"
                        >补尾款</span
                      >
                      <span
                        class="font_12 text_right line_height_24"
                        style="flex: 1"
                        >¥{{ item.ReturnArrearAmount | NumFormat }}</span
                      >
                    </div>
                  </div>
                </template>

                <template v-for="(item, index) in selectPackageCardAccountList">
                  <div :key="index + item.ID">
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24"
                        style="flex: 2"
                      >
                        {{
                          index +
                          1 +
                          selectProjectAccountList.length +
                          selectProductAccountList.length +
                          selectSavingCardAccountList.length +
                          selectTimeCardAccountList.length +
                          selectGeneralCardAccountList.length
                        }}:{{ item.Name }}
                      </span>
                    </div>
                    <div class="dis_flex">
                      <span
                        class="font_12 color_gray text-left line_height_24 marlt_10"
                        style="flex: 2"
                        >补尾款</span
                      >
                      <span
                        class="font_12 text_right line_height_24"
                        style="flex: 1"
                        >¥{{ item.ReturnArrearAmount }}</span
                      >
                    </div>
                  </div>
                </template>

                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex">
                  <span
                    class="flex_box font_12 color_gray text-left line_height_24"
                    >合计</span
                  >
                  <span class="flex_box font_12 text_right line_height_24"
                    >￥{{ arrearTotalAmount | NumFormat }}</span
                  >
                </div>
              </div>
            </el-scrollbar>
          </el-col>
          <el-col :span="16">
            <el-row type="flex" align="middle" class="dialog_bill_detail">
              <el-col :span="24">
                <div class="marbm_10">
                  <span class="font_20">待收款：</span>
                  <span class="font_20"
                    >¥{{ PayAmount | Fixed_2 | NumFormat }}</span
                  >
                </div>

                <div>
                  <span
                    >订单金额：¥{{
                      arrearTotalAmount | Fixed_2 | NumFormat
                    }}</span
                  >
                  <span
                    v-if="PayCashAmount > 0"
                    class="color_gray font_12 marlt_10"
                  >
                    付款：
                    <span class="color_red"
                      >-¥{{ PayCashAmount | Fixed_2 | NumFormat }}</span
                    >
                  </span>
                </div>
              </el-col>
            </el-row>
            <el-scrollbar class="el-scrollbar_height" style="height: 415px">
              <el-table
                :data="payList"
                size="small"
                class="padtp_15"
                :show-header="false"
              >
                <el-table-column prop="payName" label="选择收款方式">
                  <template slot-scope="scope">
                    <el-select
                      v-model="scope.row.PayMethodID"
                      placeholder="选择收款方式"
                      size="small"
                      clearable
                      filterable
                      @change="payMethodChange(scope.row)"
                    >
                      <el-option
                        v-for="item in payTypeList"
                        :key="item.ID"
                        :label="item.Name"
                        :value="item.ID"
                      ></el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="price" label="支付金额">
                  <template slot-scope="scope">
                    <el-input
                      clearable
                      v-model="scope.row.Amount"
                      size="small"
                      type="number"
                      v-enter-number
                      placeholder="请输入收款金额"
                      :disabled="scope.row.PayMethodID == ''"
                      @input="payPriceChange(scope.row)"
                    ></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="address" label="操作" width="100">
                  <template slot-scope="scope">
                    <el-button
                      type="danger"
                      icon="el-icon-close"
                      circle
                      size="mini"
                      @click="removePayClick(scope.$index)"
                      v-if="scope.$index + 1 != 1"
                    ></el-button>
                    <el-button
                      type="primary"
                      icon="el-icon-plus"
                      circle
                      size="mini"
                      @click="addPayclick"
                      v-if="scope.$index + 1 == payList.length"
                    ></el-button>
                  </template>
                </el-table-column>
              </el-table>
               <div class="martp_20" v-show="IsReturnedCommissionToo">返佣方式： 
                <el-radio v-model="ReturnedCommissionType" :label="10">现金</el-radio>
                <el-radio v-model="ReturnedCommissionType" :label="20">卡金</el-radio>
                </div>
            </el-scrollbar>
          </el-col>
         
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="dialogBill = false"
          size="small"
          :disabled="modalLoading"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="submitArrearPayClick"
          :loading="modalLoading"
          v-prevent-click
          size="small"
          >确定收款</el-button
        >
      </div>
    </el-dialog>
    <!--结账成功-->
    <el-dialog :visible.sync="dialogSuccess" width="450px">
      <div class="text_center pad_15">
        <i class="el-icon-document" style="font-size: 80px ;color: #999"></i>
        <div class="pad_15 color_primary font_weight_600 font_18">订单已结账成功</div>
      </div>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">补欠款订单号：</el-col>
        <el-col :span="12" class="text_right">{{billOrderNumber}}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="12">订单金额：</el-col>
        <el-col :span="12" class="color_red text_right">¥{{arrearTotalAmount_s | NumFormat}}</el-col>
      </el-row>
      <el-row class="pad_15 border_bottom">
        <el-col :span="5">订单备注：</el-col>
        <el-col :span="19">{{sucRemark}}</el-col>
      </el-row>

      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogSuccess = false" size="small">继续开单</el-button>
        <el-button type="primary" @click="printOrderReceipt" :loading="printLoading" size="small">打印小票</el-button>
      </div>
    </el-dialog>

    <!-- 打印小票 销售订单 -->
    <el-dialog :visible.sync="arrearCashierReceiptDialogVisible" width="300px">
      <span slot="title" class="font_14 color_333">打印小票</span>
      <div v-if="saleOrderDetail && entityName">
        <el-row>
          <el-col :span="24">
            <el-scrollbar class="el-scrollbar_height" style="height:500px">
              <div class="marrt_10">
                <div class="dis_flex">
                  <span class="flex_box text_center font_16" style="line-height:32px">{{entityName}}</span>
                </div>
                <el-divider><span class="font_12 color_gray">补尾款信息</span></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">订单编号</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{saleOrderDetail.ID}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">下单时间</span>
                  <span class="font_12 text_right line_height_24" style="flex:3;">{{saleOrderDetail.BillDate}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员姓名</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.NameEncrypt?formatName(saleOrderDetail.Name) : saleOrderDetail.Name}}</span>

                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">会员手机号</span>
                  <span class="flex_box font_12 text_right line_height_24">{{cashierReceipt.MobileEncrypt?formatPhone(saleOrderDetail.PhoneNumber):saleOrderDetail.PhoneNumber}}</span>
                </div>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">收银员</span>
                  <span class="flex_box font_12 text_right line_height_24">{{saleOrderDetail.EmployeeName}}</span>
                </div>
                <div v-if="cashierReceipt.EntityAddress" class="dis_flex">
                  <span class="flex_box6 color_gray text-left line_height_24">地址：</span>
                  <span class="flex_box font_12 text_right line_height_24">{{saleOrderDetail.AddressDetail}}</span>
                </div>
                
                <el-divider><span class="font_12 color_gray">补尾款明细</span></el-divider>

                <template v-for="(item,index) in saleOrderDetail.Project">
                  <div  :key="index + 'Project' + item.ProjectID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1}} {{item.ProjectName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                    </div>

                    <div v-if="cashierReceipt.SaleArrearBuyAmount" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount"  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >¥ {{item.BalanceArrearAmount | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >￥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.SavingCard">
                  <div :key="index + 'SavingCard' + item.SavingCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">{{index+1+saleOrderDetail.Project.length}} {{item.SavingCardName}}</span>
                    </div>
                    <div class="dis_flex" >
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">充值金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(item.AccountTotalAmount || 0).toFixed(2) | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.Amount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount"  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >¥ {{item.BalanceArrearAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.TimeCard">
                  <div :key="index+'TimeCard'+item.TimeCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length}} {{item.TimeCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <!-- <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span> -->
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount  | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount"  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >¥ {{item.BalanceArrearAmount | NumFormat}}</span>
                    </div>

                    <div  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.GeneralCard">
                  <div :key="index+'GeneralCard'+item.GeneralCardID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length}} {{item.GeneralCardName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                      <!-- <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{item.Price | NumFormat}}</span> -->
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount  | NumFormat}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount"  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >¥ {{item.BalanceArrearAmount | NumFormat}}</span>
                    </div>

                    <div  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.Product">
                  <div :key="index+'Product'+item.ProductID">
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                        {{index+1+saleOrderDetail.Project.length+saleOrderDetail.SavingCard.length+saleOrderDetail.TimeCard.length+saleOrderDetail.GeneralCard.length}} {{item.ProductName}}
                        <span class="font_12" size="mini" v-if="item.IsLargess">【赠】</span>
                      </span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBuyAmount" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.AccountTotalAmount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBuyQuantity" class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">{{item.Quantity}}</span>
                    </div>

                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{item.TotalAmount | NumFormat}}</span>
                    </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount"  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >¥ {{item.BalanceArrearAmount | NumFormat}}</span>
                    </div>
                    <div class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-if="item.IsLargess">￥0.00</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{item.TotalAmount | NumFormat}}</span>
                    </div>
                  </div>
                </template>
                <template v-for="(item,index) in saleOrderDetail.PackageCard">
                  <div :key="index+'PackageCard'+item.PackageCardID">
                    <!-- 套餐卡项目 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardProject"  :key="Pindex + 'PProjectID' + packItem.ProjectID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length
                          }}  {{packItem.Name}}
                        </span>
                      </div>
                      
                      <div v-if="cashierReceipt.SaleArrearBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount"  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >¥ {{packItem.BalanceArrearAmount | NumFormat}}</span>
                    </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;" >￥ {{item.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                    
                    <!-- 套餐卡储值卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardSavingCard"  :key="Pindex + 'PSavingCardID' + packItem.SavingCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length}}  {{packItem.Name}}
                          <span class="font_12" size="mini" v-if="packItem.IsLargess">(赠)</span>
                        </span>
                      </div>

                      <div class="dis_flex" >
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">充值金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{(packItem.AccountTotalAmount || 0).toFixed(2) | NumFormat}}</span>
                      </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.Amount | NumFormat}}</span>
                      </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount"  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >¥ {{packItem.BalanceArrearAmount | NumFormat}}</span>
                    </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.TotalAmount | NumFormat}}</span>
                      </div>

                    </div>

                    <!-- 套餐卡时效卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardTimeCard"  :key="Pindex + 'PTimeCardID' + packItem.TimeCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length}}  {{packItem.Name}}
                        </span>
                        <!-- <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span> -->
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount  | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.TotalAmount}}</span>
                      </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount"  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >¥ {{packItem.BalanceArrearAmount | NumFormat}}</span>
                    </div>

                      <div  class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.TotalAmount | NumFormat}}</span>
                      </div>

                    </div>

                    <!-- 套餐卡通用次卡 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardGeneralCard"  :key="Pindex + 'PGeneralCardID' + packItem.GeneralCardID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          +saleOrderDetail.Product.length}}  {{packItem.Name}}
                          <span class="font_12" size="mini" v-if="packItem.IsLargess">(赠)</span>
                        </span>
                        <!-- <span v-if="cashierReceipt.SaleGoodsOriginPrice" class="font_12 text_right line_height_24" style="flex:1;">￥{{packItem.PackagePrice | NumFormat}}</span> -->
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount  | NumFormat}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.TotalAmount}}</span>
                      </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount"  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >¥ {{packItem.BalanceArrearAmount | NumFormat}}</span>
                    </div>

                      <div  class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;" v-if="packItem.IsLargess">￥0.00</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                      

                    </div>

                    <!-- 套餐卡产品 -->
                    <div v-for="(packItem,Pindex) in item.PackageCardGoods.PackageCardProduct"  :key="Pindex + 'PProduct' + packItem.ProductID">
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24" style="flex:2;">
                          {{Pindex+1
                          +item.PackageCardGoods.PackageCardProject.length
                          +item.PackageCardGoods.PackageCardSavingCard.length
                          +item.PackageCardGoods.PackageCardTimeCard.length
                          +item.PackageCardGoods.PackageCardGeneralCard.length
                          +saleOrderDetail.Project.length
                          +saleOrderDetail.SavingCard.length
                          +saleOrderDetail.TimeCard.length
                          +saleOrderDetail.GeneralCard.length 
                          + saleOrderDetail.Product.length}}  {{packItem.Name}}
                        </span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBuyAmount" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.AccountTotalAmount | NumFormat}}</span>
                      </div>
                      <div v-if="cashierReceipt.SaleArrearBuyQuantity" class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">购买数量</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">{{packItem.Quantity}}</span>
                      </div>

                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">补欠款金额</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;">¥ {{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    <div v-if="cashierReceipt.SaleArrearBalanceAmount"  class="dis_flex">
                      <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">剩余欠款金额</span>
                      <span class="font_12 text_right line_height_24" style="flex:1;" >¥ {{packItem.BalanceArrearAmount | NumFormat}}</span>
                    </div>
                      <div class="dis_flex">
                        <span class="font_12 color_gray text-left line_height_24 marlt_10" style="flex:2;">小计</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;" v-if="packItem.IsLargess">￥0.00</span>
                        <span class="font_12 text_right line_height_24" style="flex:1;" v-else>￥{{packItem.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                  </div>
                </template>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex">
                  <span class="flex_box font_12 color_gray text-left line_height_24">合计</span>
                  <span class="flex_box font_12 text_right line_height_24">￥{{saleOrderDetail.TotalAmount | NumFormat}}</span>
                </div>
                <div v-if="saleOrderDetail.SaleBillPay && saleOrderDetail.SaleBillPay.length > 0 " class="dis_flex font_12">
                    <span class="flex_box6 color_gray text-left line_height_24">付款：</span>
                    <div class="flex_box">
                      <div class="dis_flex" v-for="pay in saleOrderDetail.SaleBillPay" :key="pay.ID + 'pay'">
                        <span class="flex_box color_gray line_height_24">{{pay.Name}}</span>
                        <span class="flex_box text_right line_height_24">¥ {{pay.Amount | NumFormat}}</span>
                      </div>
                    </div>
                </div>
                <!--  -->
                <div v-if="saleOrderDetail.SaleBillPaySavingCardDeduction && saleOrderDetail.SaleBillPaySavingCardDeduction.length > 0" class="dis_flex font_12">
                    <span class="flex_box6 color_gray text-left line_height_24">卡抵扣：</span>
                    <div class="flex_box">
                      <div class="dis_flex flex_box" v-for="cardPay in saleOrderDetail.SaleBillPaySavingCardDeduction" :key="cardPay.ID + 'cardPay'">
                        <span class="flex_box color_gray line_height_24">{{cardPay.Name}}</span>
                        <span class="flex_box text_right line_height_24">¥ {{cardPay.TotalAmount | NumFormat}}</span>
                      </div>
                    </div>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.PricePreferentialAmount!=0  && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">手动改价</span>
                  <span class="flex_box font_12 text_right line_height_24" v-if="saleOrderDetail.PricePreferentialAmount>0">-￥{{saleOrderDetail.PricePreferentialAmount | NumFormat}}</span>
                  <span class="flex_box font_12 text_right line_height_24" v-else>+￥{{mathAbsData(saleOrderDetail.PricePreferentialAmount) | NumFormat}}</span>
                </div>
                <div class="dis_flex" v-if="saleOrderDetail.CardPreferentialAmount>0 && cashierReceipt.SalePromotions">
                  <span class="flex_box font_12 color_gray text-left line_height_24">卡优惠</span>
                  <span class="flex_box font_12 text_right line_height_24">-￥{{saleOrderDetail.CardPreferentialAmount | NumFormat}}</span>
                </div>
                <el-divider class="sell-el-divider"></el-divider>
                <div class="dis_flex flex_dir_column font_14 font_weight_600 flex_y_center color_999 padbm_10">
                  <span>{{cashierReceipt.WriteTextFirst}}</span>
                  <span>{{cashierReceipt.WriteTextSecond}}</span>
                </div>

                <div class="dis_flex font_12">
                  <span class="flex_box6 color_gray text-left line_height_24">签字：</span>
                </div>

              </div>
            </el-scrollbar>
          </el-col>
        </el-row>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="arrearCashierReceiptDialogVisible = false;" size="small" :disabled="modalLoading">取 消</el-button>
        <el-button type="primary" @click="printArrearSaleBillContent" :loading="modalLoading" v-prevent-click size="small">打印</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import API from "@/api/iBeauty/Order/workOffArrearsAPI";
import SaleAPI from "@/api/iBeauty/Order/saleGoods";
import date from "@/components/js/date";
import cashierAPI from "@/api/iBeauty/Order/cashierReceipt";
import orderAPI from "@/api/iBeauty/Order/saleBill";
import printReceipt from "@/components/js/print";

var Enumerable = require("linq");
var socket;
export default {
  props: {
    billDate: String,
    isSupplement: Boolean,
    CustomerID: Number,
    customerFullName: String,
    customerPhoneNumber: String,
    IsReturnedCommission:Boolean,
  },
  data() {
    return {
      IsReturnedCommissionToo:null,
      customerID:"",
      dialogSuccess:false,
      arrearCashierReceiptDialogVisible:false,
      printLoading:false,
      loading: false,
      modalLoading: false,
      dialogVisible: false,
      dialogBill: false,
      dialogDeduction: false,
      dialogDeductionPackage: false,
      dialogPay: false,
      packageDetailLoading: false,
      ReturnedCommissionType:10,
      handlerName: "",
      tabHandle: "",
      goodsName: "", //搜索商品
      tabPane: "",
      entityName: "",
      userName: "",
      billOrderNumber: "",

      remark: "", //备注
      /**  网络请求的数据  */
      projectAccountList: [],
      productAccountList: [],
      savingCardAccountList: [],
      generalCardAccountList: [],
      timeCardAccountList: [],
      packageCardAccountList: [],
      packageCardAccountDetail: {},
      handlerList: [], //公共经手人列表
      productHandlerList: [], //产品经手人列表
      projectHandlerList: [], //项目经手人列表
      savingCardHandlerList: [], //储值卡经手人列表
      timeCardHandlerList: [], //时效卡经手人列表
      generalCardHandlerList: [], //通用次卡经手人列表
      packageCardHandlerList: [], //套餐卡经手人列表
      payTypeList: [], //支付方式
      /**  选中的数据  */
      selectProjectAccountList: [],
      selectProductAccountList: [],
      selectSavingCardAccountList: [],
      selectGeneralCardAccountList: [],
      selectTimeCardAccountList: [],
      selectPackageCardAccountList: [],
      /**    */
      currentSelectPackageCardItem: {},
      currentSelectPackageCardIndex: 0,
      payList: [{ PayMethodID: "", Amount: "", price: 0 }],
      handlerTypeIndex: 0,
      currentChangeHandlerItem: {}, //修改经手人的品项
      arrearTotalAmount_s:"",
      sucRemark:"",
      saleOrderDetail:"",
      cashierReceipt:"",
    };
  },
  watch:{
    CustomerID(newValue){
      var that = this;
    that.customerID = newValue;
    if (newValue != null) {
            that.changeWorkOffArrear();
          } else {
            that.clearConsumeNetWorkData();
          }
    },

    IsReturnedCommission(newVal,oldVal){
        this.IsReturnedCommissionToo = newVal
    },

    '$route.params'(newVal,oldVal){
      if(JSON.stringify(newVal) == "{}"){
        return;
      }else {
        this.IsReturnedCommissionToo = newVal.IsReturnedCommission
      }
    }
  },
  computed: {
    /**  补尾款总金额  */
    arrearTotalAmount: function () {
      var that = this;
      var totalAmount = 0;
      totalAmount += Enumerable.from(that.selectProjectAccountList).sum((i) =>
        Number(i.ReturnArrearAmount)
      );
      // that.selectProjectAccountList.forEach(item=>{
      //   totalAmount += parseFloat(item.ReturnArrearAmount);
      // })
      totalAmount += Enumerable.from(that.selectProductAccountList).sum((i) =>
        Number(i.ReturnArrearAmount)
      );

      // that.selectProductAccountList.forEach(item=>{
      //   totalAmount += parseFloat(item.ReturnArrearAmount);
      // })

      totalAmount += Enumerable.from(that.selectTimeCardAccountList).sum((i) =>
        Number(i.ReturnArrearAmount)
      );

      // that.selectTimeCardAccountList.forEach(item=>{
      //   totalAmount += parseFloat(item.ReturnArrearAmount);
      // })

      totalAmount += Enumerable.from(
        that.selectGeneralCardAccountList
      ).sum((i) => Number(i.ReturnArrearAmount));

      // that.selectGeneralCardAccountList.forEach(item=>{
      //   totalAmount += parseFloat(item.ReturnArrearAmount);
      // })
      totalAmount += Enumerable.from(
        that.selectSavingCardAccountList
      ).sum((i) => Number(i.ReturnArrearAmount));

      // that.selectSavingCardAccountList.forEach(item=>{
      //   totalAmount += parseFloat(item.ReturnArrearAmount);
      // })
      /**  套餐卡  */

      Enumerable.from(that.selectPackageCardAccountList).forEach((item) => {
        totalAmount += Enumerable.from(item.Project).sum((i) =>
          Number(i.ReturnArrearAmount)
        );
        totalAmount += Enumerable.from(item.Product).sum((i) =>
          Number(i.ReturnArrearAmount)
        );
        totalAmount += Enumerable.from(item.SavingCard).sum((i) =>
          Number(i.ReturnArrearAmount)
        );
        totalAmount += Enumerable.from(item.TimeCard).sum((i) =>
          Number(i.ReturnArrearAmount)
        );
        totalAmount += Enumerable.from(item.GeneralCard).sum((i) =>
          Number(i.ReturnArrearAmount)
        );
        // totalAmount += parseFloat(item.ReturnArrearAmount);
      });
      return totalAmount;
    },
    /**  已付款金额  */
    PayCashAmount: function () {
      var that = this;
      return Enumerable.from(that.payList).sum((i) => Number(i.price || 0));
    },
    /**  待支付金额  */
    PayAmount: function () {
      var that = this;
      return parseFloat(that.arrearTotalAmount - that.PayCashAmount);
    },
  },
  methods: {
    changeWorkOffArrear: function () {
      var that = this;
      if (that.customerID != null) {
        that.tabPane = "";
        if(that.getBillDate() == null){
          return
        }
        /**  存量数据  */
        that.getArrearProjectAccountNetwork();
        that.getarrearProductAccountNewtwork();
        that.getArrearSavingCardAccountNetwork();
        that.getArrearGeneralCardAccountNetwork();
        that.getArrearTimeCardAccountNetwork();
        that.getArrearPackageCardAccountNetwork();
        /**  经手人  */
        that.projectHandlerData();
        that.productHandlerData();
        that.savingCardHandlerData();
        that.timeCardHandlerData();
        that.generalHandlerData();
        that.packageCardHandlerData();
        that.salePayMethodData();
      } else {
        that.selectProjectAccountList = [];
        that.selectProductAccountList = [];
        that.selectSavingCardAccountList = [];
        that.selectGeneralCardAccountList = [];
        that.selectTimeCardAccountList = [];
        that.selectPackageCardAccountList = [];
      }
    },
    /**  清理上一个顾客的存量  */
    clearConsumeNetWorkData() {
      var that = this;
      that.tabPane = "";
      that.projectAccountList = [];
      that.productAccountList = [];
      that.savingCardAccountList = [];
      that.generalCardAccountList = [];
      that.timeCardAccountList = [];
      that.packageCardAccountList = [];
      that.packageCardAccountDetailList = [];

      that.selectProjectAccountList = [];
      that.selectProductAccountList = [];
      that.selectSavingCardAccountList = [];
      that.selectGeneralCardAccountList = [];
      that.selectTimeCardAccountList = [];
      that.selectPackageCardAccountList = [];
    },

    mathAbsData: function (item) {
      return Math.abs(item);
    },
    //获取开单时间
    getBillDate: function () {
      var that = this;
      return that.isSupplement
        ? that.billDate
        : date.formatDate.format(new Date(), "YYYY-MM-DD hh:mm:ss");
    },

    /**  其他  */
    // 搜索商品
    searchGoodsClick: function () {
      var that = this;
      switch (that.tabPane) {
        case "0":
          that.getArrearProjectAccountNetwork();
          break;
        case "1":
          that.getArrearSavingCardAccountNetwork();
          break;
        case "2":
          that.getArrearTimeCardAccountNetwork();
          break;
        case "3":
          that.getArrearGeneralCardAccountNetwork();
          break;
        case "4":
          that.getArrearPackageCardAccountNetwork();
          break;
        case "5":
          that.getarrearProductAccountNewtwork();
          break;
      }
    },
    // 清空
    clearClick: function () {
      var that = this;
      that.searchGoodsClick();
    },

    // 锚点
    navChange: function (index, selector) {
      var that = this;
      var anchor = this.$el.querySelector(selector);
      that.$el.querySelector(".el_scrollbar_project").scrollTop =
        anchor.offsetTop;
    },

    // 支付方式支付金额变化
    payPriceChange: function (item) {
      var that = this;
      var payAmount = (
        parseFloat(that.PayAmount) + parseFloat(item.price)
      ).toFixed(2);
      if (parseFloat(item.Amount) > parseFloat(payAmount)) {
        item.Amount = payAmount;
      }
      item.price = item.Amount;
    },
    payMethodChange: function (item) {
      var that = this;
      if (item.PayMethodID == "") {
        item.Amount = "";
      } else {
        if (item.Amount == "") {
          item.Amount = parseFloat(that.arrearTotalAmount).toFixed(2);
        }
      }
      that.payPriceChange(item);
    },

    // 删除支付
    removePayClick: function (index) {
      var that = this;
      that.payList.splice(index, 1);
    },
    // 添加支付
    addPayclick: function () {
      var that = this;
      that.payList.push({ PayMethodID: "", Amount: "", price: 0 });
    },

    // 套餐明细
    packDetailClick: function (row) {
      row.isPackDetail = !row.isPackDetail;
    },

    /**  点击事件  */
    /**  项目 =============================================  */
    ArrearProjectClick(item) {
      var that = this;
      /**  使用linq判断当前项目是否已经添加  true 项目已存在  false 项目不存在 */
      var isProject = Enumerable.from(that.selectProjectAccountList)
        .toLookup("$.ID")
        .contains(item.ID);

      if (isProject) {
        that.$message.error({
          message: "补尾款项目已存在",
          duration: 2000,
        });
      } else {
        var tempHandle = [];
        Enumerable.from(that.projectHandlerList).forEach((item) => {
          tempHandle.push({
            Name: item.Name,
            ID: item.ID,
            Employee: [],
          });
        });
        that.selectProjectAccountList.push({
          Alias: item.Alias,
          Amount: item.Amount,
          ArrearAmount: item.ArrearAmount,
          Balance: item.Balance,
          BuyDate: item.BuyDate,
          ID: item.ID,
          IsLargess: item.IsLargess,
          Name: item.Name,
          Price: item.Price,
          ProjectID: item.ProjectID,
          Quantity: item.Quantity,
          RefundBalance: item.RefundBalance,
          TotalAmount: item.TotalAmount,
          ValidBalance: item.ValidBalance,
          ReturnArrearAmount: item.ArrearAmount,
          HandleTypeList: tempHandle,
        });
      }
    },

    /**   */
    changeArrearsAmount(value, item) {
      if (value > item.ArrearAmount) {
        item.ReturnArrearAmount = item.ArrearAmount;
      }
    },

    /**  点击储值卡  */
    ArrearSavingCardClick(item) {
      var that = this;
      var isSavingCard = Enumerable.from(that.selectSavingCardAccountList)
        .toLookup("$.ID")
        .contains(item.ID);
      if (isSavingCard) {
        that.$message.error({
          message: "补尾款储值卡已存在",
          duration: 2000,
        });
      } else {
        var tempHandle = [];
        Enumerable.from(that.savingCardHandlerList).forEach((item) => {
          tempHandle.push({
            Name: item.Name,
            ID: item.ID,
            Employee: [],
          });
        });
        that.selectSavingCardAccountList.push({
          AccountID: item.AccountID,
          Alias: item.Alias,
          ArrearAmount: item.ArrearAmount,
          Balance: item.Balance,
          BuyDate: item.BuyDate,
          ID: item.ID,
          LargessBalance: item.LargessBalance,
          Name: item.Name,
          RefundAmount: item.RefundAmount,
          SavingCardID: item.SavingCardID,
          TotalAmount: item.TotalAmount,
          TotalBalance: item.TotalBalance,
          Type: item.Type,
          ValidBalance: item.ValidBalance,
          ValidDayName: item.ValidDayName,
          ReturnArrearAmount: item.ArrearAmount,
          HandleTypeList: tempHandle,
        });
      }
    },

    /** 点击时效卡   */
    ArrearTimeCardClick(item) {
      var that = this;
      var isTimeCard = Enumerable.from(that.selectTimeCardAccountList)
        .toLookup("$.ID")
        .contains(item.ID);
      if (isTimeCard) {
        that.$message.error({
          message: "补尾款时效卡已存在",
          duration: 2000,
        });
      } else {
        var tempHandle = [];
        Enumerable.from(that.timeCardHandlerList).forEach((item) => {
          tempHandle.push({
            Name: item.Name,
            ID: item.ID,
            Employee: [],
          });
        });
        that.selectTimeCardAccountList.push({
          Alias: item.Alias,
          Amount: item.Amount,
          ArrearAmount: item.ArrearAmount,
          BuyDate: item.BuyDate,
          ConsumeAmount: item.ConsumeAmount,
          ConsumeCycle: item.ConsumeCycle,
          ConsumeCycleAmount: item.ConsumeCycleAmount,
          CycleLimitAmount: item.CycleLimitAmount,
          ID: item.ID,
          IsLargess: item.IsLargess,
          Name: item.Name,
          PerformanceBalance: item.PerformanceBalance,
          PerformanceBalanceAmount: item.PerformanceBalanceAmount,
          PerformanceTimes: item.PerformanceTimes,
          Price: item.Price,
          TimeCardID: item.TimeCardID,
          TotalAmount: item.TotalAmount,
          ValidBalance: item.ValidBalance,
          ValidDayName: item.ValidDayName,
          ReturnArrearAmount: item.ArrearAmount,
          HandleTypeList: tempHandle,
        });
      }
    },

    /**  通用次卡  */
    ArrearGeneralCardClick(item) {
      var that = this;
      var isGeneralCard = Enumerable.from(that.selectGeneralCardAccountList)
        .toLookup("$.ID")
        .contains(item.ID);
      if (isGeneralCard) {
        that.$message.error({
          message: "补尾款通用次卡已存在",
          duration: 2000,
        });
      } else {
        var tempHandle = [];
        Enumerable.from(that.generalCardHandlerList).forEach((item) => {
          tempHandle.push({
            Name: item.Name,
            ID: item.ID,
            Employee: [],
          });
        });
        that.selectGeneralCardAccountList.push({
          Alias: item.Alias,
          Amount: item.Amount,
          ArrearAmount: item.ArrearAmount,
          Balance: item.Balance,
          BuyDate: item.BuyDate,
          CardTimes: item.CardTimes,
          GeneralCardID: item.GeneralCardID,
          ID: item.ID,
          IsLargess: item.IsLargess,
          Name: item.Name,
          Price: item.Price,
          RefundAmount: item.RefundAmount,
          TotalAmount: item.TotalAmount,
          ValidBalance: item.ValidBalance,
          ValidDayName: item.ValidDayName,
          ReturnArrearAmount: item.ArrearAmount,
          HandleTypeList: tempHandle,
        });
      }
    },

    /**  产品补尾款  */
    ArrearProductClick(item) {
      var that = this;
      /**  使用linq判断当前项目是否已经添加  true 项目已存在  false 项目不存在 */
      var isProduct = Enumerable.from(that.selectProductAccountList)
        .toLookup("$.ID")
        .contains(item.ID);
      if (isProduct) {
        that.$message.error({
          message: "补尾款产品已存在",
          duration: 2000,
        });
      } else {
        var tempHandle = [];
        Enumerable.from(that.productHandlerList).forEach((item) => {
          tempHandle.push({
            Name: item.Name,
            ID: item.ID,
            Employee: [],
          });
        });
        that.selectProductAccountList.push({
          Alias: item.Alias,
          Amount: item.Amount,
          ArrearAmount: item.ArrearAmount,
          Balance: item.Balance,
          BuyDate: item.BuyDate,
          ID: item.ID,
          IsLargess: item.IsLargess,
          Name: item.Name,
          Price: item.Price,
          ProductID: item.ProductID,
          Quantity: item.Quantity,
          RefundBalance: item.RefundBalance,
          TotalAmount: item.TotalAmount,
          ValidBalance: item.ValidBalance,
          ReturnArrearAmount: item.ArrearAmount,
          HandleTypeList: tempHandle,
        });
      }
    },

    /**  =====================================  */
    /**  计算套餐卡不欠款总计  */
    getPackageArrearTotalAmount(packageItem) {
      if (!packageItem) return 0;
      var totalAmount = 0;
      totalAmount += Enumerable.from(packageItem.Project).sum((i) =>
        Number(i.ReturnArrearAmount)
      );
      totalAmount += Enumerable.from(packageItem.Product).sum((i) =>
        Number(i.ReturnArrearAmount)
      );
      totalAmount += Enumerable.from(packageItem.SavingCard).sum((i) =>
        Number(i.ReturnArrearAmount)
      );
      totalAmount += Enumerable.from(packageItem.TimeCard).sum((i) =>
        Number(i.ReturnArrearAmount)
      );
      totalAmount += Enumerable.from(packageItem.GeneralCard).sum((i) =>
        Number(i.ReturnArrearAmount)
      );
      return totalAmount;
    },
    /** 套餐卡项目   */
    ArrearPackageProjectClick(item) {
      var that = this;
      /**  使用linq判断当前项目是否已经添加  true 项目已存在  false 项目不存在 */
      var isPackage = Enumerable.from(that.selectPackageCardAccountList)
        .toLookup("$.ID")
        .contains(that.currentSelectPackageCardItem.ID);
      if (isPackage) {
        var packageItem = Enumerable.from(
          that.selectPackageCardAccountList
        ).firstOrDefault((i) => i.ID == that.currentSelectPackageCardItem.ID);
        var isProject = Enumerable.from(packageItem.Project)
          .toLookup("$.ID")
          .contains(item.ID);
        if (isProject) {
          that.$message.error({
            message: "套餐卡中的项目已存在",
            duration: 2000,
          });
        } else {
          packageItem.Project.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            ProjectID: item.ProjectID,
            Quantity: item.Quantity,
            RefundBalance: item.RefundBalance,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ReturnArrearAmount: item.ArrearAmount,
          });
        }
      } else {
        var tempTypeList = [];
        that.packageCardHandlerList.forEach((item) => {
          tempTypeList.push({
            Name: item.Name,
            ID: item.ID,
            Employee: [],
          });
        });

        var projectItem = {
          Alias: item.Alias,
          Amount: item.Amount,
          ArrearAmount: item.ArrearAmount,
          Balance: item.Balance,
          BuyDate: item.BuyDate,
          ID: item.ID,
          IsLargess: item.IsLargess,
          Name: item.Name,
          Price: item.Price,
          ProjectID: item.ProjectID,
          Quantity: item.Quantity,
          RefundBalance: item.RefundBalance,
          TotalAmount: item.TotalAmount,
          ValidBalance: item.ValidBalance,
          ReturnArrearAmount: item.ArrearAmount,
        };

        that.selectPackageCardAccountList.push({
          Alias: that.currentSelectPackageCardItem.Alias,
          ArrearAmount: that.currentSelectPackageCardItem.ArrearAmount,
          BuyDate: that.currentSelectPackageCardItem.BuyDate,
          ID: that.currentSelectPackageCardItem.ID,
          IsLargess: that.currentSelectPackageCardItem.IsLargess,
          Name: that.currentSelectPackageCardItem.Name,
          PackageCardID: that.currentSelectPackageCardItem.PackageCardID,
          Price: that.currentSelectPackageCardItem.Price,
          TotalAmount: that.currentSelectPackageCardItem.TotalAmount,
          ValidDayName: that.currentSelectPackageCardItem.ValidDayName,
          HandleTypeList: tempTypeList,
          Project: [projectItem],
          Product: [],
          GeneralCard: [],
          TimeCard: [],
          SavingCard: [],
        });
      }
    },

    /**  储值卡  */
    ArrearPackageSavingCardClick(item) {
      var that = this;
      /**  使用linq判断当前项目是否已经添加  true 项目已存在  false 项目不存在 */
      var isPackage = Enumerable.from(that.selectPackageCardAccountList)
        .toLookup("$.ID")
        .contains(that.currentSelectPackageCardItem.ID);
      if (isPackage) {
        var packageItem = Enumerable.from(
          that.selectPackageCardAccountList
        ).firstOrDefault((i) => i.ID == that.currentSelectPackageCardItem.ID);
        var isSavingCard = Enumerable.from(packageItem.SavingCard)
          .toLookup("$.ID")
          .contains(item.ID);
        if (isSavingCard) {
          that.$message.error({
            message: "套餐卡中的储值卡已存在",
            duration: 2000,
          });
        } else {
          packageItem.SavingCard.push({
            AccountID: item.AccountID,
            Alias: item.Alias,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            LargessBalance: item.LargessBalance,
            Name: item.Name,
            RefundAmount: item.RefundAmount,
            SavingCardID: item.SavingCardID,
            TotalAmount: item.TotalAmount,
            TotalBalance: item.TotalBalance,
            Type: item.Type,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            ReturnArrearAmount: item.ArrearAmount,
          });
        }
      } else {
        var tempTypeList = [];
        that.packageCardHandlerList.forEach((item) => {
          tempTypeList.push({
            Name: item.Name,
            ID: item.ID,
            Employee: [],
          });
        });

        var savingCardItem = {
          AccountID: item.AccountID,
          Alias: item.Alias,
          ArrearAmount: item.ArrearAmount,
          Balance: item.Balance,
          BuyDate: item.BuyDate,
          ID: item.ID,
          LargessBalance: item.LargessBalance,
          Name: item.Name,
          RefundAmount: item.RefundAmount,
          SavingCardID: item.SavingCardID,
          TotalAmount: item.TotalAmount,
          TotalBalance: item.TotalBalance,
          Type: item.Type,
          ValidBalance: item.ValidBalance,
          ValidDayName: item.ValidDayName,
          ReturnArrearAmount: item.ArrearAmount,
        };

        that.selectPackageCardAccountList.push({
          Alias: that.currentSelectPackageCardItem.Alias,
          ArrearAmount: that.currentSelectPackageCardItem.ArrearAmount,
          BuyDate: that.currentSelectPackageCardItem.BuyDate,
          ID: that.currentSelectPackageCardItem.ID,
          IsLargess: that.currentSelectPackageCardItem.IsLargess,
          Name: that.currentSelectPackageCardItem.Name,
          PackageCardID: that.currentSelectPackageCardItem.PackageCardID,
          Price: that.currentSelectPackageCardItem.Price,
          TotalAmount: that.currentSelectPackageCardItem.TotalAmount,
          ValidDayName: that.currentSelectPackageCardItem.ValidDayName,
          HandleTypeList: tempTypeList,
          Project: [],
          Product: [],
          GeneralCard: [],
          TimeCard: [],
          SavingCard: [savingCardItem],
        });
      }
    },

    /** 时效卡   */
    ArrearPackageTimeCardClick(item) {
      var that = this;
      /**  使用linq判断当前项目是否已经添加  true 项目已存在  false 项目不存在 */
      var isPackage = Enumerable.from(that.selectPackageCardAccountList)
        .toLookup("$.ID")
        .contains(that.currentSelectPackageCardItem.ID);
      if (isPackage) {
        var packageItem = Enumerable.from(
          that.selectPackageCardAccountList
        ).firstOrDefault((i) => i.ID == that.currentSelectPackageCardItem.ID);
        var isSavingCard = Enumerable.from(packageItem.TimeCard)
          .toLookup("$.ID")
          .contains(item.ID);
        if (isSavingCard) {
          that.$message.error({
            message: "套餐卡中的时效卡已存在",
            duration: 2000,
          });
        } else {
          packageItem.TimeCard.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            BuyDate: item.BuyDate,
            ConsumeAmount: item.ConsumeAmount,
            ConsumeCycle: item.ConsumeCycle,
            ConsumeCycleAmount: item.ConsumeCycleAmount,
            CycleLimitAmount: item.CycleLimitAmount,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            PerformanceBalance: item.PerformanceBalance,
            PerformanceBalanceAmount: item.PerformanceBalanceAmount,
            PerformanceTimes: item.PerformanceTimes,
            Price: item.Price,
            TimeCardID: item.TimeCardID,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            ReturnArrearAmount: item.ArrearAmount,
          });
        }
      } else {
        var tempTypeList = [];
        that.packageCardHandlerList.forEach((item) => {
          tempTypeList.push({
            Name: item.Name,
            ID: item.ID,
            Employee: [],
          });
        });

        var timeCardItem = {
          Alias: item.Alias,
          Amount: item.Amount,
          ArrearAmount: item.ArrearAmount,
          BuyDate: item.BuyDate,
          ConsumeAmount: item.ConsumeAmount,
          ConsumeCycle: item.ConsumeCycle,
          ConsumeCycleAmount: item.ConsumeCycleAmount,
          CycleLimitAmount: item.CycleLimitAmount,
          ID: item.ID,
          IsLargess: item.IsLargess,
          Name: item.Name,
          PerformanceBalance: item.PerformanceBalance,
          PerformanceBalanceAmount: item.PerformanceBalanceAmount,
          PerformanceTimes: item.PerformanceTimes,
          Price: item.Price,
          TimeCardID: item.TimeCardID,
          TotalAmount: item.TotalAmount,
          ValidBalance: item.ValidBalance,
          ValidDayName: item.ValidDayName,
          ReturnArrearAmount: item.ArrearAmount,
        };

        that.selectPackageCardAccountList.push({
          Alias: that.currentSelectPackageCardItem.Alias,
          ArrearAmount: that.currentSelectPackageCardItem.ArrearAmount,
          BuyDate: that.currentSelectPackageCardItem.BuyDate,
          ID: that.currentSelectPackageCardItem.ID,
          IsLargess: that.currentSelectPackageCardItem.IsLargess,
          Name: that.currentSelectPackageCardItem.Name,
          PackageCardID: that.currentSelectPackageCardItem.PackageCardID,
          Price: that.currentSelectPackageCardItem.Price,
          TotalAmount: that.currentSelectPackageCardItem.TotalAmount,
          ValidDayName: that.currentSelectPackageCardItem.ValidDayName,
          HandleTypeList: tempTypeList,
          Project: [],
          Product: [],
          GeneralCard: [],
          TimeCard: [timeCardItem],
          SavingCard: [],
        });
      }
    },

    /**  通用次卡  */
    ArrearPackageGeneralCardClick(item) {
      var that = this;
      /**  使用linq判断当前项目是否已经添加  true 项目已存在  false 项目不存在 */
      var isPackage = Enumerable.from(that.selectPackageCardAccountList)
        .toLookup("$.ID")
        .contains(that.currentSelectPackageCardItem.ID);
      if (isPackage) {
        var packageItem = Enumerable.from(
          that.selectPackageCardAccountList
        ).firstOrDefault((i) => i.ID == that.currentSelectPackageCardItem.ID);
        var isSavingCard = Enumerable.from(packageItem.GeneralCard)
          .toLookup("$.ID")
          .contains(item.ID);
        if (isSavingCard) {
          that.$message.error({
            message: "套餐卡中的通用次卡已存在",
            duration: 2000,
          });
        } else {
          packageItem.GeneralCard.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            CardTimes: item.CardTimes,
            GeneralCardID: item.GeneralCardID,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            RefundAmount: item.RefundAmount,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            ReturnArrearAmount: item.ArrearAmount,
          });
        }
      } else {
        var tempTypeList = [];
        that.packageCardHandlerList.forEach((item) => {
          tempTypeList.push({
            Name: item.Name,
            ID: item.ID,
            Employee: [],
          });
        });

        var generalCardItem = {
          Alias: item.Alias,
          Amount: item.Amount,
          ArrearAmount: item.ArrearAmount,
          Balance: item.Balance,
          BuyDate: item.BuyDate,
          CardTimes: item.CardTimes,
          GeneralCardID: item.GeneralCardID,
          ID: item.ID,
          IsLargess: item.IsLargess,
          Name: item.Name,
          Price: item.Price,
          RefundAmount: item.RefundAmount,
          TotalAmount: item.TotalAmount,
          ValidBalance: item.ValidBalance,
          ValidDayName: item.ValidDayName,
          ReturnArrearAmount: item.ArrearAmount,
        };

        that.selectPackageCardAccountList.push({
          Alias: that.currentSelectPackageCardItem.Alias,
          ArrearAmount: that.currentSelectPackageCardItem.ArrearAmount,
          BuyDate: that.currentSelectPackageCardItem.BuyDate,
          ID: that.currentSelectPackageCardItem.ID,
          IsLargess: that.currentSelectPackageCardItem.IsLargess,
          Name: that.currentSelectPackageCardItem.Name,
          PackageCardID: that.currentSelectPackageCardItem.PackageCardID,
          Price: that.currentSelectPackageCardItem.Price,
          TotalAmount: that.currentSelectPackageCardItem.TotalAmount,
          ValidDayName: that.currentSelectPackageCardItem.ValidDayName,
          HandleTypeList: tempTypeList,
          Project: [],
          Product: [],
          GeneralCard: [generalCardItem],
          TimeCard: [],
          SavingCard: [],
        });
      }
    },

    /** 产品   */
    ArrearPackageProductClick(item) {
      var that = this;
      /**  使用linq判断当前项目是否已经添加  true 项目已存在  false 项目不存在 */
      var isPackage = Enumerable.from(that.selectPackageCardAccountList)
        .toLookup("$.ID")
        .contains(that.currentSelectPackageCardItem.ID);
      if (isPackage) {
        var packageItem = Enumerable.from(
          that.selectPackageCardAccountList
        ).firstOrDefault((i) => i.ID == that.currentSelectPackageCardItem.ID);
        var isSavingCard = Enumerable.from(packageItem.Product)
          .toLookup("$.ID")
          .contains(item.ID);
        if (isSavingCard) {
          that.$message.error({
            message: "套餐卡中的产品已存在",
            duration: 2000,
          });
        } else {
          packageItem.Product.push({
            Alias: item.Alias,
            Amount: item.Amount,
            ArrearAmount: item.ArrearAmount,
            Balance: item.Balance,
            BuyDate: item.BuyDate,
            ID: item.ID,
            IsLargess: item.IsLargess,
            Name: item.Name,
            Price: item.Price,
            ProductID: item.ProductID,
            Quantity: item.Quantity,
            RefundBalance: item.RefundBalance,
            TotalAmount: item.TotalAmount,
            ValidBalance: item.ValidBalance,
            ValidDayName: item.ValidDayName,
            ReturnArrearAmount: item.ArrearAmount,
          });
        }
      } else {
        var tempTypeList = [];
        that.packageCardHandlerList.forEach((item) => {
          tempTypeList.push({
            Name: item.Name,
            ID: item.ID,
            Employee: [],
          });
        });

        var productItem = {
          Alias: item.Alias,
          Amount: item.Amount,
          ArrearAmount: item.ArrearAmount,
          Balance: item.Balance,
          BuyDate: item.BuyDate,
          ID: item.ID,
          IsLargess: item.IsLargess,
          Name: item.Name,
          Price: item.Price,
          ProductID: item.ProductID,
          Quantity: item.Quantity,
          RefundBalance: item.RefundBalance,
          TotalAmount: item.TotalAmount,
          ValidBalance: item.ValidBalance,
          ValidDayName: item.ValidDayName,
          ReturnArrearAmount: item.ArrearAmount,
        };

        that.selectPackageCardAccountList.push({
          Alias: that.currentSelectPackageCardItem.Alias,
          ArrearAmount: that.currentSelectPackageCardItem.ArrearAmount,
          BuyDate: that.currentSelectPackageCardItem.BuyDate,
          ID: that.currentSelectPackageCardItem.ID,
          IsLargess: that.currentSelectPackageCardItem.IsLargess,
          Name: that.currentSelectPackageCardItem.Name,
          PackageCardID: that.currentSelectPackageCardItem.PackageCardID,
          Price: that.currentSelectPackageCardItem.Price,
          TotalAmount: that.currentSelectPackageCardItem.TotalAmount,
          ValidDayName: that.currentSelectPackageCardItem.ValidDayName,
          HandleTypeList: tempTypeList,
          Project: [],
          Product: [productItem],
          GeneralCard: [],
          TimeCard: [],
          SavingCard: [],
        });
      }
    },

    /** 项目删除   */
    removeArrearPackageProjectItemClick(item, index, projectIndex) {
      var that = this;
      item.Project.splice(projectIndex, 1);
      if (
        item.Project.length == 0 &&
        item.Product.length == 0 &&
        item.GeneralCard.length == 0 &&
        item.TimeCard.length == 0 &&
        item.SavingCard.length == 0
      ) {
        that.selectPackageCardAccountList.splice(index, 1);
      }
    },

    /** 储值卡删除   */
    removeArrearPackageSavingCardItemClick(item, index, savingIndex) {
      var that = this;
      item.SavingCard.splice(savingIndex, 1);
      if (
        item.Project.length == 0 &&
        item.Product.length == 0 &&
        item.GeneralCard.length == 0 &&
        item.TimeCard.length == 0 &&
        item.SavingCard.length == 0
      ) {
        that.selectPackageCardAccountList.splice(index, 1);
      }
    },

    /** 时效卡删除   */
    removeArrearPackageTimeCardItemClick(item, index, timeIndex) {
      var that = this;
      item.TimeCard.splice(timeIndex, 1);
      if (
        item.Project.length == 0 &&
        item.Product.length == 0 &&
        item.GeneralCard.length == 0 &&
        item.TimeCard.length == 0 &&
        item.SavingCard.length == 0
      ) {
        that.selectPackageCardAccountList.splice(index, 1);
      }
    },

    /** 通用次卡删除   */
    removeArrearPackageGeneralCardItemClick(item, index, generalIndex) {
      var that = this;
      item.GeneralCard.splice(generalIndex, 1);
      if (
        item.GeneralCard.length == 0 &&
        item.Product.length == 0 &&
        item.GeneralCard.length == 0 &&
        item.TimeCard.length == 0 &&
        item.SavingCard.length == 0
      ) {
        that.selectPackageCardAccountList.splice(index, 1);
      }
    },

    /** 产品删除   */
    removeArrearPackageProductItemClick(item, index, productIndex) {
      var that = this;
      item.Product.splice(productIndex, 1);
      if (
        item.Product.length == 0 &&
        item.Product.length == 0 &&
        item.GeneralCard.length == 0 &&
        item.TimeCard.length == 0 &&
        item.SavingCard.length == 0
      ) {
        that.selectPackageCardAccountList.splice(index, 1);
      }
    },

    /**  套餐卡分类点击  */
    clickPackageCagegory(item, index) {
      var that = this;
      that.packageCardAccountDetailList = [];
      that.currentSelectPackageCardIndex = index;
      that.currentSelectPackageCardItem =
        that.packageCardAccountList[that.currentSelectPackageCardIndex];
      that.getArrearPackageCardAccountDetailsNetwork();
    },

    /**  删除已选择品项  */
    removeArrearsItemClick(item, index, type, childIndex) {
      var that = this;
      switch (type) {
        case 0:
          that.selectProjectAccountList.splice(index, 1);
          break;
        case 1:
          that.selectProductAccountList.splice(index, 1);
          break;

        case 2:
          that.selectSavingCardAccountList.splice(index, 1);
          break;
        case 3:
          item.ChildItems.splice(childIndex, 1);
          if (item.ChildItems.length <= 0) {
            that.selectPackageCardAccountList.splice(index, 1);
          }
          break;
        case 4:
          that.selectTimeCardAccountList.splice(index, 1);
          break;

        case 5:
          that.selectGeneralCardAccountList.splice(index, 1);
          break;

        default:
          break;
      }
    },

    // 经手人
    employeeHandleClick: function (type, item) {
      var that = this;
      that.tabHandle = '0'
      var emplayee = [];
      switch (type) {
        case 0:
          that.handlerList = that.projectHandlerList;
          break;
        case 1:
          that.handlerList = that.savingCardHandlerList;
          break;
        case 2:
          that.handlerList = that.timeCardHandlerList;
          break;
        case 3:
          that.handlerList = that.generalCardHandlerList;
          break;
        case 4:
          that.handlerList = that.packageCardHandlerList;
          break;
        case 5:
          that.handlerList = that.productHandlerList;
          break;
      }
      item.HandleTypeList.forEach(function (hand) {
        hand.Employee.forEach(function (emp) {
          emplayee.push({ ID: emp.ID, Discount: emp.Discount });
        });
      });
      that.handlerList.forEach(function (handler) {
        handler.Employee.forEach(function (emp) {
          emp.Checked = false;
          emp.Discount = "";
          emplayee.forEach(function (i) {
            if (emp.ID == i.ID) {
              emp.Checked = true;
              emp.Discount = i.Discount;
            }
          });
        });
      });

      that.handlerTypeIndex = type;
      that.currentChangeHandlerItem = item;
      that.dialogVisible = true;
    },
    // 经手人确认选择
    submitHandleClick: function () {
      var that = this;
      var goodsHandlerList = JSON.parse(JSON.stringify(that.handlerList));
      goodsHandlerList.forEach(function (item) {
        item.Employee = Enumerable.from(item.Employee)
          .where(function (i) {
            return i.Checked;
          })
          .toArray();
      });
      that.currentChangeHandlerItem.HandleTypeList = [];
      that.currentChangeHandlerItem.HandleTypeList = goodsHandlerList;
      that.dialogVisible = false;
      /**  
        switch (that.handlerTypeIndex) {
          case 0:
            break;
          case 1:
            that.selectSavingCard = that.selectGoods;
            break;
          case 2:
            that.selectTimeCard = that.selectGoods;
            break;
          case 3:
            that.selectGeneralCard = that.selectGoods;
            break;
          case 4:
            that.selectPackageCard = that.selectGoods;
            break;
          case 5:
            that.selectProduct = that.selectGoods;
            break;
        }
        */
    },
    // 删除经手人
    removeHandleClick: function (item, index) {
      item.Employee.splice(index, 1);
    },
    // 经手人选择
    handlerCheckedChange: function (row, item) {
      var that = this;
      var discount = 0;
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
      if (!item.Checked) {
        item.Discount = "";
      } else {
        if (item.Discount == "") {
          if (discount > 100) {
            item.Discount = 0;
          } else {
            item.Discount = 100 - discount;
          }
        }
      }
    },
    // 百分比
    handlerPercentChange: function (row, item) {
      var that = this;
      var discount = 0;
      if (item.Discount != "") {
        item.Discount = parseFloat(item.Discount);
      }
      if (item.Discount > 100) {
        item.Discount = 100;
      }
      var employee = Enumerable.from(row)
        .where(function (i) {
          return i.Checked;
        })
        .toArray();
      employee.forEach(function (emp) {
        var Discount = emp.Discount;
        if (Discount == "") {
          Discount = 0;
        }
        discount = parseFloat(discount) + parseFloat(Discount);
      });
      if (parseFloat(discount) > 100) {
        item.Discount = 100 - (discount - item.Discount);
        that.$message.error("比例总和不能超过100%");
      }
    },
    tabClick() {},

    /**  结账   */
    billClick: function () {
      var that = this;
      if (
        (that.selectProjectAccountList.length == 0) &
        (that.selectProductAccountList.length == 0) &
        (that.selectTimeCardAccountList.length == 0) &
        (that.selectGeneralCardAccountList.length == 0) &
        (that.selectSavingCardAccountList.length == 0) &
        (that.selectPackageCardAccountList.length == 0)
      ) {
        that.$message.error("请选择补尾款商品！");
        return;
      }
      if (that.arrearTotalAmount <= 0) {
        that.$message.error("请填写补尾款金额！");

        return;
      }

      that.payList = [{ PayMethodID: "", Amount: "", price: 0 }];
      that.dialogBill = true;
    },

    /**  确认结帐  */
    submitArrearPayClick: function () {
      var that = this;
      if(that.selectProjectAccountList.length == 0 &&
      that.selectSavingCardAccountList.length == 0 &&
      that.selectTimeCardAccountList.length == 0 &&
      that.selectGeneralCardAccountList.length == 0 &&
      that.selectPackageCardAccountList.length == 0 &&
      that.selectProductAccountList.length == 0
      ){
        that.$message.error("请选择商品");
        return;
      }
      if (that.getBillDate() == null) {
        that.$message.error("请输入补录日期");
        return;
      }


      if (that.PayAmount > 0) {
        that.$message.error({
          message: "请填写收款金额。",
        });
        return;
      }

      var arrearParams = {};
      arrearParams.CustomerID = that.customerID;
      arrearParams.BillDate = that.getBillDate();
      arrearParams.Amount = that.arrearTotalAmount;
      arrearParams.Remark = that.remark;

      /**  项目  */
      arrearParams.Project = Enumerable.from(that.selectProjectAccountList)
        .doAction(function (item) {
          item.ProjectArrearHandler = [];
          Enumerable.from(item.HandleTypeList).forEach(function (handler) {
            Enumerable.from(handler.Employee).forEach(function (employee) {
              item.ProjectArrearHandler.push({
                ArrearHandlerID: employee.SaleHandlerID,
                EmployeeID: employee.EmployeeID,
                Scale: employee.Discount,
              });
            });
          });
        })
        .select((val) => ({
          ProjectAccountID: val.ID,
          Amount: val.ReturnArrearAmount,
          ProjectArrearHandler: val.ProjectArrearHandler,
        }))
        .toArray();

      /**  储值卡  */
      arrearParams.SavingCard = Enumerable.from(
        that.selectSavingCardAccountList
      )
        .doAction((item) => {
          item.SavingCardArrearHandler = [];
          item.HandleTypeList.forEach(function (handler) {
            handler.Employee.forEach(function (employee) {
              item.SavingCardArrearHandler.push({
                ArrearHandlerID: employee.SaleHandlerID,
                EmployeeID: employee.EmployeeID,
                Scale: employee.Discount,
              });
            });
          });
        })
        .select((val) => ({
          SavingCardAccountID: val.ID,
          Amount: val.ReturnArrearAmount,
          SavingCardArrearHandler: val.SavingCardArrearHandler,
        }))
        .toArray();

      /**  时效卡  */
      arrearParams.TimeCard = Enumerable.from(that.selectTimeCardAccountList)
        .doAction((item) => {
          item.TimeCardArrearHandler = [];
          item.HandleTypeList.forEach(function (handler) {
            handler.Employee.forEach(function (employee) {
              item.TimeCardArrearHandler.push({
                ArrearHandlerID: employee.SaleHandlerID,
                EmployeeID: employee.EmployeeID,
                Scale: employee.Discount,
              });
            });
          });
        })
        .select((val) => ({
          TimeCardAccountID: val.ID,
          Amount: val.ReturnArrearAmount,
          TimeCardArrearHandler: val.TimeCardArrearHandler,
        }))
        .toArray();

      /**  通用次卡  */
      arrearParams.GeneralCard = Enumerable.from(
        that.selectGeneralCardAccountList
      )
        .doAction((item) => {
          item.GeneralCardArrearHandler = [];
          item.HandleTypeList.forEach(function (handler) {
            handler.Employee.forEach(function (employee) {
              item.GeneralCardArrearHandler.push({
                ArrearHandlerID: employee.SaleHandlerID,
                EmployeeID: employee.EmployeeID,
                Scale: employee.Discount,
              });
            });
          });
        })
        .select((val) => ({
          GeneralCardAccountID: val.ID,
          Amount: val.ReturnArrearAmount,
          GeneralCardArrearHandler: val.GeneralCardArrearHandler,
        }))
        .toArray();

      /**  套餐卡  */
      arrearParams.PackageCard = Enumerable.from(
        that.selectPackageCardAccountList
      )
        .doAction((item) => {
          item.PackageCardArrearHandler = [];
          item.HandleTypeList.forEach(function (handler) {
            handler.Employee.forEach(function (employee) {
              item.PackageCardArrearHandler.push({
                ArrearHandlerID: employee.SaleHandlerID,
                EmployeeID: employee.EmployeeID,
                Scale: employee.Discount,
              });
            });
          });
          (item.PackageAmount = that.getPackageArrearTotalAmount(item)),
            (item.Project_s = Enumerable.from(item.Project)
              .select((val) => ({
                ProjectAccountID: val.ID,
                Amount: val.ReturnArrearAmount,
              }))
              .toArray());
          item.Product_s = Enumerable.from(item.Product)
            .select((val) => ({
              ProductAccountID: val.ID,
              Amount: val.ReturnArrearAmount,
            }))
            .toArray();
          item.TimeCard_s = Enumerable.from(item.TimeCard)
            .select((val) => ({
              TimeCardAccountID: val.ID,
              Amount: val.ReturnArrearAmount,
            }))
            .toArray();
          item.SavingCard_s = Enumerable.from(item.SavingCard)
            .select((val) => ({
              SavingCardAccountID: val.ID,
              Amount: val.ReturnArrearAmount,
            }))
            .toArray();
          item.GeneralCard_s = Enumerable.from(item.GeneralCard)
            .select((val) => ({
              GeneralCardAccountID: val.ID,
              Amount: val.ReturnArrearAmount,
            }))
            .toArray();
        })
        .select((val) => ({
          PackageCardAccountID: val.ID,
          Amount: val.PackageAmount,
          PackageCardArrearHandler: val.PackageCardArrearHandler,
          Project: val.Project_s,
          Product: val.Product_s,
          GeneralCard: val.GeneralCard_s,
          TimeCard: val.TimeCard_s,
          SavingCard: val.SavingCard_s,
        }))
        .toArray();

      /**  产品  */
      arrearParams.Product = Enumerable.from(that.selectProductAccountList)
        .doAction((item) => {
          item.ProductArrearHandler = [];
          item.HandleTypeList.forEach(function (handler) {
            handler.Employee.forEach(function (employee) {
              item.ProductArrearHandler.push({
                ArrearHandlerID: employee.SaleHandlerID,
                EmployeeID: employee.EmployeeID,
                Scale: employee.Discount,
              });
            });
          });
        })
        .select((val) => ({
          ProductAccountID: val.ID,
          Amount: val.ReturnArrearAmount,
          ProductArrearHandler: val.ProductArrearHandler,
        }))
        .toArray();

      arrearParams.PayMethod = Enumerable.from(that.payList)
        .where(function (i) {
          return i.PayMethodID != "" && i.Amount != "";
        })
        .select((val) => ({
          PayMethodID: val.PayMethodID,
          Amount: val.Amount,
        }))
        .toArray();
      arrearParams.ReturnedCommissionType = this.ReturnedCommissionType;
      that.modalLoading = true;
      that.setArrearCreateOrder(arrearParams);
    },

    /**  网络数据请求  */
    /**  53.1.补尾款项目存量列表  */
    getArrearProjectAccountNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      API.getArrearProjectAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectAccountList = res.Data;
            if (that.tabPane == "" && that.projectAccountList.length > 0) {
              that.tabPane = "0";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  53.2.补尾款产品存量列表  */
    getarrearProductAccountNewtwork: function () {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      API.getArrearProductAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.productAccountList = res.Data;

            if (that.tabPane == "" && that.productAccountList.length > 0) {
              that.tabPane = "5";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  53.3.补尾款储值卡存量列表  */
    getArrearSavingCardAccountNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      API.getArrearSavingCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardAccountList = res.Data;
            if (that.tabPane == "" && that.savingCardAccountList.length > 0) {
              that.tabPane = "1";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  53.4.补尾款通用次卡存量列表   */
    getArrearGeneralCardAccountNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      API.getArrearGeneralCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.generalCardAccountList = res.Data;
            if (that.tabPane == "" && that.generalCardAccountList.length > 0) {
              that.tabPane = "3";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  53.5.补尾款时效卡存量列表  */
    getArrearTimeCardAccountNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {
        CustomerID: that.customerID,
        BillDate: that.getBillDate(),
        Name: that.goodsName,
      };
      API.getArrearTimeCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.timeCardAccountList = res.Data;

            if (that.tabPane == "" && that.timeCardAccountList.length > 0) {
              that.tabPane = "2";
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /**  53.6.补尾款套餐卡存量列表  */
    getArrearPackageCardAccountNetwork: function () {
      var that = this;
      that.packageDetailLoading = true;
      var params = {
        CustomerID: that.customerID,
        Name: that.goodsName,
      };
      API.getArrearPackageCardAccount(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageCardAccountList = res.Data;
            if (that.tabPane == "" && that.packageCardAccountList.length > 0) {
              that.tabPane = "4";
            }
            if (that.packageCardAccountList.length > 0) {
              that.packageCardAccountDetailList = [];
              that.currentSelectPackageCardIndex = 0;
              that.currentSelectPackageCardItem =
                that.packageCardAccountList[that.currentSelectPackageCardIndex];
              that.getArrearPackageCardAccountDetailsNetwork();
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.packageDetailLoading = false;
        });
    },
    /**   53.7.补尾款套餐卡存量明细 */
    getArrearPackageCardAccountDetailsNetwork: function () {
      var that = this;
      that.loading = true;
      var params = {
        PackageCardAccountID: that.currentSelectPackageCardItem.ID,
        BillDate: that.getBillDate(),
      };
      API.getArrearPackageCardAccountDetails(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageCardAccountDetail = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**   项目经手人 */
    projectHandlerData: function () {
      var that = this;
      that.loading = true;
      SaleAPI.getProjectHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.projectHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**  产品经手人  */
    productHandlerData: function () {
      var that = this;
      that.loading = true;
      SaleAPI.getProductHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.productHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**   通用次卡经手人 */
    generalHandlerData: function () {
      var that = this;
      that.loading = true;
      SaleAPI.getGeneralCardHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.generalCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**    时效卡经手人 */
    timeCardHandlerData: function () {
      var that = this;
      that.loading = true;
      SaleAPI.getTimeCardHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.timeCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**   储值卡经手人 */
    savingCardHandlerData: function () {
      var that = this;
      that.loading = true;
      SaleAPI.getSavingCardHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.savingCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**   套餐卡经手人 */
    packageCardHandlerData: function () {
      var that = this;
      that.loading = true;
      SaleAPI.getPackageCardHandler()
        .then((res) => {
          if (res.StateCode == 200) {
            that.packageCardHandlerList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**  支付方式  */
    salePayMethodData: function () {
      var that = this;
      that.loading = true;
      SaleAPI.getSalePayMethod()
        .then((res) => {
          if (res.StateCode == 200) {
            that.payTypeList = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**  53.8.补尾款结账  */
    setArrearCreateOrder: function (params) {
      var that = this;
      API.getArrearCreate(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.arrearTotalAmount_s = that.arrearTotalAmount;
            that.sucRemark = that.remark;
            that.selectProjectAccountList = [];
            that.selectProductAccountList = [];
            that.selectSavingCardAccountList = [];
            that.selectTimeCardAccountList = [];
            that.selectPackageCardAccountList = [];
            that.selectGeneralCardAccountList = [];
            (that.payList = [{ PayMethodID: "", Amount: "", price: 0 }]),
              that.changeWorkOffArrear();
            that.dialogBill = false;
            that.modalLoading = false;
            that.remark = "";
            that.billOrderNumber = res.Data.BillID;
            that.dialogSuccess = true;
            that.$parent.IsSupplement = false;
            that.$parent.BillDate = null;
            // that.$message.success({
            //   message: "补尾款成功",
            //   duration: 2000,
            // });
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.modalLoading = false;
        });
    },
    /** 打印小票   */
    printOrderReceipt(){
      var that = this;
      that.getOrderDetail();
      that.getReceiptConfig();
    },

    /** 获取订单详情 */
    getOrderDetail() {
      var that = this;
      that.printLoading = true;

      var params = {
        SaleBillID: that.billOrderNumber,
      };

      orderAPI.getOrderDetail(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.saleOrderDetail = res.Data;
            that.arrearCashierReceiptDialogVisible = true;
          }
        })
        .finally(function () {
          that.printLoading = false;
        });
    },
    /**  获取打印内容  */
    printArrearSaleBillContent(){
      let that = this
      let params = {
        SaleBillID:that.billOrderNumber,
      }
      cashierAPI.printArrearSaleBillContent(params).then(res=>{
        if (res.StateCode == 200) {
           for (let index = 0; index < res.Data.copies; index++) {
            printReceipt.doActionPrint(res.Data.printDocuments,(request)=>{
              socket.send(JSON.stringify(request));
            }) 
          }
        }
      }).finally(()=>{

      })
    },

    /**  获取小票配置信息  */
    getReceiptConfig(){
      var that = this;
      cashierAPI.getReceiptConfigBill().then(res=>{
        if(res.StateCode == 200){
          that.cashierReceipt = res.Data;
        }
      }).finally(()=>{})
    },


    // 姓名隐藏
    formatName(name){
     return printReceipt.hiddenName(name)
    },
    // 手机号隐藏
    formatPhone(phone){
     return printReceipt.hiddenPhone(phone)
    }


  },
  mounted() {
    this.IsReturnedCommissionToo = this.$route.params.IsReturnedCommission
    this.customerID = this.CustomerID;
    socket = printReceipt.getSocket(res=>{
      if(res.status == 'success'){
        that.$message.success({
          message:"打印成功",
          duration:2000,
        })
        that.arrearCashierReceiptDialogVisible = false;
      }
    });
    var that = this;
    if (localStorage.getItem("access-user")) {
      that.userName = JSON.parse(
        localStorage.getItem("access-user")
      ).EmployeeName;

      that.entityName = JSON.parse(
        localStorage.getItem("access-user")
      ).EntityName;
    }

    /**  已选顾客请求  */
    if (that.customerID != null) {
      that.changeWorkOffArrear();
    }
  },
};
</script>

<style lang="scss">
.workOffArrears {
  .arrearGoods {
    .goods-item {
      line-height: 36px;
      font-size: 12px;
      margin-left: 20px;
      margin-right: 5px;
    }
    .goods-lable {
      color: #606266;
    }

    .el-card__header {
      padding: 10px 20px;
      background-color: #f5f7fa;
    }
  }

  .inputWith {
    width: 50%;
  }
  .back_project_col {
    background-color: #fafafa;
  }

  .project_content {
    font-size: 13px;
    height: 100%;
    .project_left {
      border-right: 1px solid #eee;
      height: 100%;
      color: #303133;
      .el-tabs {
        height: calc(100% - 62px);
        .el-tabs__header {
          margin: 0;
          .el-tabs__nav-scroll {
            margin-left: 15px;
          }
        }
        .el-tabs__content {
          height: calc(100% - 40px);
          .el-tab-pane {
            height: 100%;
            .category_project {
              height: 100%;
              .category {
                height: 100%;
                .category_select {
                  color: #039be5;
                }
              }
              .project {
                height: 100%;
                overflow: auto;
                .el-collapse {
                  .el-collapse-item__header {
                    background-color: #f5f7fa !important;
                    padding: 0 10px;
                  }
                  .el-collapse-item__content {
                    padding: 0;
                  }
                }
                .category_sub_list {
                  overflow-x: auto;
                  .category_sub_select {
                    color: #039be5;
                  }
                }
                .project_list {
                  height: 100%;
                }
                .producct_list {
                  height: 100%;
                }
              }
            }
          }
        }
      }
    }
    .project_right {
      height: 100%;
      .el-main {
        padding: 0;
        .row_header {
          background-color: #ecf8ff;
          padding: 10px;
        }
        .employee_num {
          width: 90px;
          .el-input-group__append {
            padding: 0 10px;
          }
        }

        .el-form-item__label {
          font-size: 13px !important;
        }
      }
      .el-footer {
        height: initial !important;
        padding: 10px;
      }
    }
  }

  // .el-icon-sort {
  //   color: #666;
  //   font-size: 14px;
  //   transform: rotate(90deg);
  // }

  .dialog_bill_detail {
    // border: 1px solid #409eff;
    background-color: #ecf8ff;
    padding: 15px;
    border-radius: 5px;
  }

  .el-scrollbar_height {
    height: 100%;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  .el-scrollbar_x_width {
    .el-scrollbar__wrap {
      .el-scrollbar__view {
        white-space: nowrap;
        .el-menu-item {
          display: inline-block;
        }
        .is-active {
          a {
            color: #409eff;
          }
        }
      }
    }
  }

  .savingCardDeduction {
    .el-table tr {
      background-color: rgb(247, 248, 250);
    }
  }

  .saving_discount {
    .el-table__row {
      background-color: #f5f7fa;
    }
  }

  .arrearSavingCardAmount {
    width: 50%;
  }

  .el-divider {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .sell-el-divider {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>


